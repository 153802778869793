import React, { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import PropTypes from 'prop-types';
import Select from 'react-select';

const BatchesList = ({ id: id, onSelectBatch, ane}) => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [batches, setBatches]=useState([])
  const [selectedBatches, setSelectedBatches] =useState('')
  
  useEffect(() => {
    const fetchBatches = async () => {
      if (!id) return;
      try {
        const ID = id?.map(items => {
          return items.productID
        })
        const response = await fetch(`http://localhost:8000/api/products/${ID}`);
        const data = await response.json();
        const options = data.data.product.productBatch.map((batch) => ({
          label: batch.sellingPrice,
          value: batch._id,
          qyt: parseInt(batch.qty)
        }));

        setBatches(options);
      } catch (error) {
        console.error("Error!", error);
      }
    };

    fetchBatches();
  }, [id]);

  const handleBatchChange = (batch) => {
    setSelectedBatches(batch);
  };
  
  const submit = () => {
    onSelectBatch(selectedBatches)
    ane(true)
    handleClose()
  }
  
  console.table(selectedBatches)
  console.table(batches)

  BatchesList.propTypes = {
    id: PropTypes.string.isRequired,
    ane:PropTypes.bool.isRequired,
    onSelectBatch: PropTypes.func.isRequired,
  };

  return (
    <>
      <Button variant="primary" size="sm" onClick={handleShow}>
        Add Batch
      </Button>

      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Select One or Multiple Batches</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Select
            options={batches}
            value={selectedBatches}
            onChange={handleBatchChange}
            isMulti
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={submit}>Submit</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default BatchesList;