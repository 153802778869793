import React from 'react'

function StockAdjustment() {
  return (
    <div>
      asdsd
    </div>
  )
}

export default StockAdjustment
