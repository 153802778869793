import React, { useState, useEffect } from "react";

import TextEditor from "../../../feature-module/inventory/texteditor";

import Select from "react-select";
import { DatePicker } from "antd";
const { RangePicker } = DatePicker;
import PropTypes from "prop-types";
import { updateLeaveEmployee } from "../../../service/leavesEmployeeApiService";
import { getAllLeaveTypes } from "../../../service/leaveApiServices/leaveTypesApi";

const EditLeaveEmployee = ({ leave }) => {
  const leaveTypeOptions = [
    { value: "Choose Status", label: "Choose Status" },
    { value: "Full Day", label: "Full Day" },
    { value: "Half Day", label: "Half Day" },
  ];

  const [selectedDate, setSelectedDate] = useState([null, null]);
  const [dateRange, setDateRange] = useState([]);
  const [selectedLeaveType, setSelectedLeaveType] = useState("");
  const [reason, setReason] = useState("");
  const [leaveDays, setLeaveDays] = useState([]);
  const [leaveType, setLeaveType] = useState([]);

  useEffect(() => {
    // Fetch the leave details (this could be the selected leave to edit)
    console.log("leave:", leave);
    if (leave) {
      //     setReason(leave.reason);
      setReason("Reason 01");
    }
  }, [leave]);

  useEffect(() => {
    const fetchLeaveTypes = async (id) => {
      try {
        const leaveTypeRecord = await getAllLeaveTypes({
          id,
          search: "",
          setLoading: () => {}, // Optional loading state
        });
        // console.log("LeaveTypes:", leaveTypeRecord);

        if (leaveTypeRecord) {
          const options = leaveTypeRecord.map((leave) => ({
            value: leave._id,
            label: leave.name,
            leaveCount: leave.minimumLeaveCount,
          }));

          setLeaveType([{ value: "Choose", label: "Choose" }, ...options]);
        }
      } catch (error) {
        console.error("Error fetching leave Types:", error);
      }
    };

    fetchLeaveTypes();
  }, []);

  // Handle Date Change
  const formatDate = (date) => {
    const d = new Date(date);
    let month = "" + (d.getMonth() + 1);
    let day = "" + d.getDate();
    const year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  };

  // Handle Date Change
  const handleDateChange = (dates) => {
    setSelectedDate(dates);
    if (dates && dates[0] && dates[1]) {
      const start = new Date(dates[0]);
      const end = new Date(dates[1]);
      const daysArray = [];
      let dayCount = 1;

      // Iterate through the date range and push each date into the array
      let currentDate = new Date(start);
      while (currentDate.getTime() <= end.getTime()) {
        daysArray.push({
          day: `Day ${dayCount}`,
          date: formatDate(currentDate), // Format date as YYYY-MM-DD
          leaveType: null,
        });
        currentDate.setDate(currentDate.getDate() + 1); // Increment the date by 1 day
        dayCount++;
      }

      setDateRange(daysArray);
      setLeaveDays(daysArray); // Initialize the leaveDays state
    } else {
      setDateRange([]);
      setLeaveDays([]);
    }
  };

  // Handle Leave Type Change for individual days
  const handleLeaveTypeChange = (index, selectedOption) => {
    const updatedLeaveDays = [...leaveDays];
    updatedLeaveDays[index].leaveType = selectedOption.value;
    setLeaveDays(updatedLeaveDays);
  };

  // Handle Submit
  const handleSubmit = async (e) => {
    e.preventDefault();

    // Prepare the leave data
    const leaveDetails = {
      leavetype: selectedLeaveType.value, // Assuming the overall leave type is selected
      leaveDays: leaveDays.map((day) => ({
        date: day.date,
        Day: day.leaveType, // Full Day or Half Day
      })),
      reason: reason || null,
      startDate: selectedDate[0].format("YYYY-MM-DD"),
      endDate: selectedDate[1].format("YYYY-MM-DD"),
      approval: false, // Set default approval to false
    };
    console.log("leaveDetails payload:", leaveDetails);
    try {
      // Call the service function to submit the data
      const response = updateLeaveEmployee(leave, leaveDetails);
      console.log("Leave submitted successfully:", response);
      alert("Leave submitted successfully");
    } catch (error) {
      console.error("Failed to submit leave:", error);
    }
  };

  return (
    <div>
      {/*Edit */}
      <div className="modal fade" id="edit-units">
        <div className="modal-dialog modal-dialog-centered custom-modal-two">
          <div className="modal-content">
            <div className="page-wrapper-new p-0">
              <div className="content">
                <div className="modal-header border-0 custom-modal-header">
                  <div className="page-title">
                    <h4>Edit Leave</h4>
                  </div>
                  <button
                    type="button"
                    className="close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">×</span>
                  </button>
                </div>
                <div className="modal-body custom-modal-body">
                  <form onSubmit={handleSubmit}>
                    <div className="row">
                      <div className="col-lg-12">
                        <div className="input-blocks">
                          <label>Date Range</label>
                          <div className="input-groupicon calender-input">
                            <RangePicker
                              selected={selectedDate}
                              onChange={handleDateChange}
                              className="filterdatepicker"
                              dateFormat="dd-MM-yyyy"
                              placeholder={["Start Date", "End Date"]}
                              getPopupContainer={(triggerNode) =>
                                triggerNode.parentNode
                              }
                            />
                          </div>
                        </div>
                      </div>
                      {dateRange.map((item, index) => (
                        <div key={index} className="col-lg-12">
                          <div className="apply-leave">
                            <div className="leave-apply">
                              <div className="leave-date">
                                <span>{item.day}</span>
                                <p>{item.date}</p>
                              </div>
                              <div className="leave-time">
                                <div className="input-blocks mb-0">
                                  <Select
                                    className="select"
                                    options={leaveTypeOptions}
                                    placeholder="Select Leave Day"
                                    onChange={(selectedOption) =>
                                      handleLeaveTypeChange(
                                        index,
                                        selectedOption
                                      )
                                    }
                                    value={leaveTypeOptions.find(
                                      (option) =>
                                        option.value === item.leaveType
                                    )}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}

                      <div className="col-lg-12">
                        <div className="mb-3">
                          <label className="form-label">
                            Select Leave Type
                          </label>

                          <Select
                            className="select"
                            options={leaveType}
                            placeholder="Select Leave Type"
                            onChange={setSelectedLeaveType}
                          />
                        </div>
                      </div>

                      <div className="col-lg-12">
                        <div className="mb-3 summer-description-box mb-0">
                          <label className="form-label">Reason</label>
                          <div id="summernote" />
                          <TextEditor
                            className="form-label"
                            value={reason}
                            onChange={(event) => setReason(event.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="modal-footer-btn">
                      <button
                        type="button"
                        className="btn btn-cancel me-2"
                        data-bs-dismiss="modal"
                      >
                        Cancel
                      </button>
                      <button to="#" className="btn btn-submit">
                        Submit
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* /Add Leave */}
    </div>
  );
};
EditLeaveEmployee.propTypes = {
  leave: PropTypes.object.isRequired,
};
export default EditLeaveEmployee;
