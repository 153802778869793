import React, { useEffect, useState } from 'react';
//import { FaPlus, FaMinus } from 'react-icons/fa';
import PropTypes from "prop-types";
import './style.css';
import { creteJobTypeMethod } from '../../../service/ApiServices'; 
import Swal from 'sweetalert2';
import { Spin} from 'antd';
import Select from 'react-select';
import { getAllBodyTypeMethod } from '../../../service/styleService';


const CreateJobTypeModal = ({ handleCancel, handleJobTypeAdding, selectedJobType }) => {
    const [jobTypeName, setJobTypeName] = useState('');
    const [loading, setLoading] = useState(false);
    const [price, setPrice] = useState('');
    const [selectedStyle, setSelectedStyle] = useState([]);
    const [serviceOptions, setServiceOptions] = useState([]);
    const isEditMode = !!selectedJobType;
    const handleInputChange = async (selectedOption) => {
        setSelectedStyle(selectedOption ? selectedOption : []);
        console.log(selectedOption);
    };

    const handleSave = () => {
        if (jobTypeName === '') {
            showWarning("Please enter Job Type Name");
            return;
        }
        if(price === '' || parseFloat(price) === 0 || parseFloat(price) < 0) {
            showWarning("Please enter valid price");
            return;
        }
        if (selectedStyle.length === 0) {
            showWarning("Please select at least one style");
            return;
        }

        creteJobTypeMethod({
            price: price,
            type: jobTypeName,
            styles: selectedStyle.map(item=>item.value),
            handleCancel,
            handleReset,
            showSuccess,
            handleJobTypeAdding,
            typeId: selectedJobType?.value,
            setLoading
        });
    };

    const handleReset = () => {
        setJobTypeName('');
        setPrice('');
        setSelectedStyle([]);
    };
    const editClose=()=>{
        setJobTypeName(selectedJobType.label);
        console.log("JOB",selectedJobType)
        //setSelectedStyle(selectedJobType.styles.map(style => ({ value: style._id, label: style.styleName })));
        console.log(selectedJobType.styles)
        setSelectedStyle(selectedJobType.styles.map(style => ({
            value: style._id,
            label: style.styleName
        })))
        setPrice(selectedJobType.price);
    }

    const showSuccess = () => {
        return Swal.fire({
            title: "Good job!",
            text: "You Successfully Saved Job Type!",
            icon: "success"
        });
    };

    const showWarning = (str) => {
        return Swal.fire({
            title: "Error",
            text: str,
            icon: "error",
        });
    };

    useEffect(() => {
        if (selectedJobType) {
            setJobTypeName(selectedJobType.label);
            console.log("JOB",selectedJobType)
            //setSelectedStyle(selectedJobType.styles.map(style => ({ value: style._id, label: style.styleName })));
            console.log(selectedJobType.styles)
            setSelectedStyle(selectedJobType.styles.map(style => ({
                value: style._id,
                label: style.styleName
            })))
            setPrice(selectedJobType.price);
        }
    }, [selectedJobType]);

    useEffect(() => {
        const fetchStyles = async () => {
            try {
                const response = await getAllBodyTypeMethod({ search: '', setLoading });
                console.log('API Response:', response);
                const options = response.map(item => ({
                    value: item._id,
                    label: item.styleName
                }));
                setServiceOptions(options);
            } catch (error) {
                console.error("Error in fetching Service Names", error);
                showWarning('Failed to fetch job types');
            } finally {
                setLoading(false);
            }
        };

        fetchStyles();
    }, [setLoading]);

    return (
        <div className="modal-content">
            <div className="modal-header border-0 custom-modal-header">
                <div className="page-title">
                    <h4>{selectedJobType ? "Edit Service" : "Create Service"}</h4>
                    <p className="mb-0">Fill in the required fields to create a</p>
                </div>
            </div>
            <div className="modal-body custom-modal-body">
                <form>
                    <div className="row mb-3">
                        <div className="col-12">
                            <div className="input-blocks">
                                <label htmlFor="jobTypeName">Service Name</label>
                                <input
                                    type="text"
                                    id="jobTypeName"
                                    className="form-control"
                                    aria-label="Job Type Name"
                                    value={jobTypeName}
                                    onChange={(event) => setJobTypeName(event.target.value)}
                                />
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="input-blocks">
                                <label htmlFor="price">Price (Rs.)</label>
                                <input
                                    type="number"
                                    id="price"
                                    className="form-control"
                                    aria-label="Price"
                                    value={price}
                                    onChange={(event) => setPrice(event.target.value)}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="input-blocks">
                                <label>Styles</label>
                                <Select
                                    className="select"
                                    options={serviceOptions}
                                    placeholder="Select a style"
                                    value={selectedStyle}
                                    onChange={handleInputChange}
                                    isMulti={true}
                                />
                            </div>
                        </div>
                    </div>
                </form>
                <div style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center"
                }} >
                    <button
                        type="button"
                        className="btn btn-cancel"
                        onClick={() => {
                            handleCancel();
                            if (!isEditMode) handleReset();
                            if (isEditMode) editClose();
                        }}
                    >
                        Close
                    </button>
                    <div>
                        <button
                            type="button"
                            className="btn btn-reset me-2"
                            onClick={handleReset}
                        >
                            Reset
                        </button>
                        <button className="btn btn-submit" onClick={handleSave}>
                            {loading ? <Spin size='small' /> : "Save"}
                        </button>
                    </div>
                    
                </div>

            </div>
        </div>
    );
};

CreateJobTypeModal.propTypes = {
    handleCancel: PropTypes.func.isRequired,
    handleJobTypeAdding: PropTypes.func.isRequired,
    selectedJobType: PropTypes.object.isRequired
};

export default CreateJobTypeModal;



