import React, { useState, useEffect } from 'react';
import { Modal, Spin, Table } from 'antd';
import { PlusCircle } from 'react-feather';
import { Link } from 'react-router-dom';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import {deleteUnit, getUnits } from '../../service/ApiServices';
import Swal from 'sweetalert2';
import CreateUnitModal from './addUnit';
import { API_BASE_URL } from "../../../src/environment";

export const Units = () => {
    const [search, setSearch] = useState('');
    const [debouncedSearch, setDebouncedSearch] = useState(search);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedUnit, setSelectedUnit] = useState(null);
    const queryClient = useQueryClient();

    const storedUserData = localStorage.getItem("user");
    const userData = JSON.parse(storedUserData);
    const id = userData?.data?.uid; //
    const [userRightssub, setUserRightssub] = useState([]);
    useEffect(() => {
      const fetchUserRightssub = async () => {
        try {
          const response = await fetch(`${API_BASE_URL}/userright/${id}`);
          if (!response.ok) {
            throw new Error("Failed to fetch user rights");
          }
          const data = await response.json();
          console.log("Fetched User Sub Data:", data.data);
          setUserRightssub(data.data || []); // Ensure it's always an array
        } catch (error) {
          console.error("Error fetching data:", error);
          setUserRightssub([]); // Set to empty array in case of error
        }
      };
    
      fetchUserRightssub(); // Fetch the data on component mount
    }, [id]);
    const hasJobRightWithAdd = userRightssub.some(right => right.right === 'units' && right.adds === 1);
    const hasJobRightWithedit = userRightssub.some(right => right.right === 'units' && right.updates === 1);
    //const hasJobRightWithedown = userRightssub.some(right => right.right === 'category-list' && right.fdownload === 1);  
    const hasJobRightWithedel = userRightssub.some(right => right.right === 'units' && right.deletes=== 1); 
      
  










    useEffect(() => {
        const handler = setTimeout(() => {
            setDebouncedSearch(search);
        }, 500);
        return () => {
            clearTimeout(handler);
        };
    }, [search]);

    const fetchUnits = () => getUnits(debouncedSearch);

    const { isLoading, error, data } = useQuery(['units', debouncedSearch], fetchUnits, {
        staleTime: Infinity,
        cacheTime: Infinity,
        refetchOnWindowFocus: false,
    });

    const mutation = useMutation(deleteUnit);

    const handleDelete = (id) => {
        Swal.fire({
          title: "Are you sure?",
          text: "You want to delete this unit?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Yes",
          cancelButtonText: "No",
          preConfirm: async () => {
            Swal.showLoading(); 
            try {
              await mutation.mutateAsync(id);
              Swal.fire({
                title: "Success",
                text: "Unit deleted successfully!",
                icon: "success",
              });
              queryClient.invalidateQueries('units');
            } catch (error) {
              let errorMessage = "Failed to process request.";
              if (error.response) {
                const { status, data } = error.response;
                if (status === 400 && data.message) {
                  errorMessage = data.message;
                } else if (status === 500) {
                  errorMessage = "Server error. Please try again later.";
                }
              }
              Swal.fire({
                title: "Error",
                text: errorMessage,
                icon: "error",
              });
            }
          },
          allowOutsideClick: () => !Swal.isLoading()
        });
      }

    const showWarning = (str) => {
        return Swal.fire({
            title: "Error",
            text: str,
            icon: "error",
        });
    };

    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Variation',
            dataIndex: 'variation',
            key: 'variation',
        },
        {
            title: 'Created At',
            dataIndex: 'createdAt',
            key: 'createdAt',
            render: (_, record) => {
                return <span>{new Date(record.createdAt).toLocaleDateString()}</span>;
            }
        },
        {
            title: 'Action',
            key: 'action',
            render: (_, record) => (
                <td className="action-table-data">
                {!hasJobRightWithedit && (
                    <Link
                    className="me-2 p-2"
                    onClick={() => {
                        setSelectedUnit(record);
                        setIsModalOpen(true);
                    }}>
                    <i data-feather="edit" className="feather-edit"></i>
                </Link>
                )}
                {!hasJobRightWithedel && (
               <Link
               className="me-2 p-2"
               onClick={() => {
                   handleDelete(record._id);
               }}

                >
                    <i data-feather="trash-2" className="feather-trash-2"></i>
                </Link>
                )}
    
                </td>
            ),
        },
    ];

    if (error) {
        showWarning('An error occurred while fetching data');
    }

    const handleSearch = (e) => {
        setSearch(e.target.value);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
        setSelectedUnit(null);
    };

    return (
        <div className="page-wrapper">
            <div className="content">
                <div className="page-header">
                    <div className="add-item d-flex">
                        <div className="page-title">
                            <h4>Units</h4>
                            <h6>Manage all your units from below</h6>
                        </div>
                    </div>
                    <div className="page-btn">
                    {!hasJobRightWithAdd && (
                        <a className="btn btn-added" onClick={() => setIsModalOpen(true)}>
                        <PlusCircle className="me-2" />
                        Add New Unit
                        </a>
                    )}
                    </div>
                </div>
                <div className="card table-list-card">
                    <div className="card-body">
                        <div className="table-top">
                            <div className="search-set">
                                <div className="search-input">
                                    <input
                                        type="search"
                                        className="form-control"
                                        placeholder="Search"
                                        value={search}
                                        onChange={handleSearch}
                                    />
                                    <Link className="btn btn-searchset">
                                        <i data-feather="search" className="feather-search" />
                                    </Link>
                                </div>
                                {/*<div className="wordset">*/}
                                {/*    <ul>*/}
                                {/*        <li>*/}
                                {/*            <a href="#" data-bs-toggle="tooltip" data-bs-placement="top" title="pdf">*/}
                                {/*                <img src="assets/img/icons/pdf.svg" alt="pdf" />*/}
                                {/*            </a>*/}
                                {/*        </li>*/}
                                {/*        <li>*/}
                                {/*            <a href="#" data-bs-toggle="tooltip" data-bs-placement="top" title="excel">*/}
                                {/*                <img src="assets/img/icons/excel.svg" alt="excel" />*/}
                                {/*            </a>*/}
                                {/*        </li>*/}
                                {/*        <li>*/}
                                {/*            <a href="#" data-bs-toggle="tooltip" data-bs-placement="top" title="print">*/}
                                {/*                <img src="assets/img/icons/printer.svg" alt="print" />*/}
                                {/*            </a>*/}
                                {/*        </li>*/}
                                {/*    </ul>*/}
                                {/*</div>*/}
                            </div>
                        </div>
                        <div className="table-responsive">
                            {isLoading ? (
                                <div style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    height: '100px',
                                }}>
                                    <Spin size='large' />
                                </div>
                            ) : (
                                <Table
                                    columns={columns}
                                    dataSource={data?.data?.data || []}
                                    pagination={{
                                        pageSize: 10,
                                        showSizeChanger: false,
                                    }}
                                />
                            )}
                        </div>
                        <Modal
                            zIndex={200000}
                            open={isModalOpen}
                            onCancel={handleCancel}
                            footer={null}
                            maskClosable={false}
                            closable={false}
                            width="45vw"
                        >
                            <CreateUnitModal
                                handleCancel={handleCancel}
                                selectedUnit={selectedUnit}
                            />
                        </Modal>
                    </div>
                </div>
            </div>
        </div>
    );
};


