import React, { useEffect, useState } from 'react';
import { Modal, Table, Tag } from 'antd';
import { PlusCircle } from 'react-feather';
import { Link } from 'react-router-dom';
import CreateBodyTypeModal from '../../core/modals/inventory/createBodyType';
import { getAllBodyTypeMethod } from '../../service/ApiServices';
import { API_BASE_URL } from "../../../src/environment";
const BodyType = () => {
    const [data, setData] = useState([]);
    const [isJobTypeAdd, setIsJobTypeAdd] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedBodyType, setSelectedBodyType] = useState(null);
    const [search, setSearch] = useState('');
    const [loading, setLoading] = useState(false);

    const storedUserData = localStorage.getItem("user");
    const userData = JSON.parse(storedUserData);
    const id = userData?.data?.uid; //
    const [userRightssub, setUserRightssub] = useState([]);
    useEffect(() => {
      const fetchUserRightssub = async () => {
        try {
          const response = await fetch(`${API_BASE_URL}/userright/${id}`);
          if (!response.ok) {
            throw new Error("Failed to fetch user rights");
          }
          const data = await response.json();
          console.log("Fetched User Sub Data:", data.data);
          setUserRightssub(data.data || []); // Ensure it's always an array
        } catch (error) {
          console.error("Error fetching data:", error);
          setUserRightssub([]); // Set to empty array in case of error
        }
      };
      fetchUserRightssub(); // Fetch the data on component mount
    }, [id]);
    const hasJobRightWithAdd = userRightssub.some(right => right.right === 'Body-Types' && right.adds === 1);
    const hasJobRightWithedit = userRightssub.some(right => right.right === 'Body-Types' && right.updates === 1);
    //const hasJobRightWithedown = userRightssub.some(right => right.right === 'Job' && right.fdownload === 1);  
    //const hasJobRightWithedel = userRightssub.some(right => right.right === 'Job' && right.fdownload === 1); 








    useEffect(() => {
        const handler = setTimeout(() => {
            const fetchData = async () => {
                setLoading(true);
                let data = await getAllBodyTypeMethod({ search, setLoading });
                setData(data);
            };
            fetchData();
            setIsJobTypeAdd(false);
            setSelectedBodyType(null);
        }, 600);

        return () => {
            clearTimeout(handler);
        };
    }, [search, isJobTypeAdd]);

    const handleOk = () => {
        setIsModalOpen(false);
    };

    const handleCancel = () => {
        setSelectedBodyType(null)
        setIsModalOpen(false);
    };

    const handleJobTypeAdding = () => {
        setIsJobTypeAdd(true);
    };

    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Types',
            dataIndex: 'types',
            key: 'types',
            render: (types) => {
                return (
                    <ul style={{ listStyleType: 'disc', paddingLeft: '0px',listStyle:"none" }}>
                        {types.map((type, i) => (
                            <li key={i}> <Tag>{type}</Tag> </li>
                        ))}
                    </ul>
                );
            },
        },
        {
            title: 'Action',
            key: 'action',
            render: (_, record) => (
                <td className="action-table-data">
                        {hasJobRightWithedit && (
                             <Link
                             className="me-2 p-2"
                             to="#"
                             onClick={() => {
                                 setSelectedBodyType(record);
                                 setIsModalOpen(true);
                             }}
                         >
                             <i data-feather="edit" className="feather-edit"></i>
                         </Link>
                        )}  
                 </td>
            ),
        },
    ];


    return (
        <div className="page-wrapper">
            <div className="content">
                <div className="page-header">
                    <div className="add-item d-flex">
                        <div className="page-title">
                            <h4>Body Types</h4>
                            <h6>Manage Body Types</h6>
                        </div>
                    </div>
                    <div className="page-btn">
                        {hasJobRightWithAdd && (
                               <a className="btn btn-added" onClick={() => setIsModalOpen(true)}>
                                 <PlusCircle className="me-2" />
                                  Add New Type
                               </a>
                        )}
                    </div>
                </div>

                <div className="card table-list-card">
                    <div className="card-body">
                        <div className="table-top">
                            <div className="search-set">
                                <div className="search-input">
                                    <input
                                        type="search"
                                        className="form-control"
                                        placeholder="Search"
                                        value={search}
                                        onChange={(e) => setSearch(e.target.value)}
                                    />
                                    <Link className="btn btn-searchset">
                                        <i data-feather="search" className="feather-search" />
                                    </Link>
                                </div>
                            </div>

                        </div>
                        <div className="table-responsive">
                            <Table loading={loading} columns={columns} dataSource={data} rowKey="value" pagination={false} />
                        </div>
                    </div>
                </div>
            </div>

            <Modal
                zIndex={200000}
                open={isModalOpen}
                onOk={handleOk}
                onCancel={handleCancel}
                footer={null}
                maskClosable={false}
                closable={false}
            >
                <CreateBodyTypeModal handleJobTypeAdding={handleJobTypeAdding} handleCancel={handleCancel} selectedBodyType={selectedBodyType} />
            </Modal>
        </div>
    );
};

export default BodyType;
