import { Card, Empty, Modal, Popconfirm, Spin, Table, Tag } from 'antd';
import React, { useEffect, useState } from 'react';
import { PlusCircle } from 'react-feather';
import { Link } from 'react-router-dom';
import { DatePicker } from 'antd';
import { createIssuanceMethod, deleteIssuanceMethod, getAllIssuancesMethod, getAllProducts, getJobByJobNoMethod } from '../../../service/ApiServices';
import Swal from 'sweetalert2';
const { RangePicker } = DatePicker;
import dayjs from 'dayjs';
import { API_BASE_URL } from "../../../environment";


const Issuance = () => {
  const startOfCurrentMonth = dayjs().startOf('month');
  const endOfCurrentMonth = dayjs().endOf('month');
  const [search, setSearch] = useState("");
  const [selectedRange, setSelectedRange] = useState([
    startOfCurrentMonth,
    endOfCurrentMonth
  ]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [jobNo, setJobNo] = useState("");
  const [searchJob, setSearchJob] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [loading3, setLoading3] = useState(false);
  const [success, setSuccess] = useState(false)
  const [addData, setAddData] = useState([])
  const [tableData, setTableData] = useState([]);
  const [products, setProducts] = useState([])
  const [isDeleted, setIsDeleted] = useState(false)
  const [selectedProduct, setSelectedProduct] = useState("")
  const [qty, setQty] = useState(0)
  const [issuanceData, setIssuanceData] = useState({
    data: [],
    page: 1,
    limit: 10,
    total: 0
  });
  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setAddData([])
    setSearchJob(null)
    setJobNo("")
  };


  const storedUserData = localStorage.getItem("user");
  const userData = JSON.parse(storedUserData);
  const id = userData?.data?.uid; //
  const [userRightssub, setUserRightssub] = useState([]);
  useEffect(() => {
    const fetchUserRightssub = async () => {
      try {
        const response = await fetch(`${API_BASE_URL}/userright/${id}`);
        if (!response.ok) {
          throw new Error("Failed to fetch user rights");
        }
        const data = await response.json();
        console.log("Fetched User Sub Data:", data.data);
        setUserRightssub(data.data || []); // Ensure it's always an array
      } catch (error) {
        console.error("Error fetching data:", error);
        setUserRightssub([]); // Set to empty array in case of error
      }
    };
  
    fetchUserRightssub(); // Fetch the data on component mount
  }, [id]);
  const hasJobRightWithAdd = userRightssub.some(right => right.right === 'issuance' && right.adds === 1);
 // const hasJobRightWithedit = userRightssub.some(right => right.right === 'grn' && right.updates === 1);
 // const hasJobRightWithedown = userRightssub.some(right => right.right === 'grn' && right.fdownload === 1);  
  const hasJobRightWithedel = userRightssub.some(right => right.right === 'issuance' && right.deletes === 1); 
 // const hasJobRightWitheview = userRightssub.some(right => right.right === 'grn' && right.viewes === 1); 


  const findJobByJobno = (jobNo) => {
    if (!jobNo || jobNo.length !== 6) {
      showWarning("Please Enter a valid Job No (6 digits)")
      return;
    }
    getJobByJobNoMethod({
      jobNo,
      setLoading,
      setData: setSearchJob
    })
  }

  const handleQtyChange = (e, record) => {
    const { value } = e.target;
    if (value > 0) {
      const updatedData = addData.map(item =>
        item.product._id === record.product._id
          ? { ...item, qty: Number(value) }
          : item
      );
      setAddData(updatedData);
    } else {
      showWarning("Quantity cannot be 0 or negative");
    }
  };
  const enableEditing = (record) => {
    const updatedData = addData.map(item => {
      if (item.product._id === record.product._id) {
        return {
          ...item,
          isEditing: true,
          initialQty: item.qty // Store initial value
        };
      }
      return item;
    });
    setAddData(updatedData);
  };

  const columns = [
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type'
    },
    {
      title: 'Measurements',
      dataIndex: 'measurements',
      key: 'measurements',
      render: (measurements) => (
        <Tag color='gold'>{measurements}</Tag>
      )
    },
    {
      title: 'Quantity',
      dataIndex: 'qty',
      key: 'qty',
      render: (qty) => (
        <Tag color='cyan'>{qty}</Tag>
      )
    },
    {
      title: 'Styles',
      dataIndex: 'styles',
      key: 'styles',
      render: (styles) => (
        styles?.map((style, index) => (
          <Card key={index} size="small" bordered={false} style={{ marginBottom: '4px', background: '#f0f2f5' }}>

                  <li key={style._id}>
                    {style.style.styleName}:
                    {style.selectedValues.map(value => (
                        <Tag key={value} color="green" style={{ marginLeft: '5px' }}>
                          {value}
                        </Tag>
                    ))}
                  </li>

          </Card>
        ))
      )
    },
    {
      title: 'Body Types',
      dataIndex: 'bodyTypes',
      key: 'bodyTypes',
      render: (bodyTypes) => (
        bodyTypes?.map((bodyType, index) => (
          <Card key={index} size="small" bordered={false} style={{ marginBottom: '4px', background: '#f0f2f5' }}>
            <strong>{bodyType.name}</strong>: <Tag color='blue'>{bodyType.values.join(', ')}</Tag>
          </Card>
        ))
      )
    }
  ];
  const saveEdit = (record) => {
    const updatedData = addData.map(item =>
      item.product._id === record.product._id ? { ...item, isEditing: false } : item
    );
    setAddData(updatedData);
  };
  const cancelEdit = (record) => {
    const updatedData = addData.map(item => {
      if (item.product._id === record.product._id) {
        return {
          ...item,
          isEditing: false,
          qty: item.initialQty // Restore initial value
        };
      }
      return item;
    });
    setAddData(updatedData);
  };
  const columnsForAddData = [
    {
      title: "Product Name",
      dataIndex: ["product", "productName"],
    },
    {
      title: "Qty",
      dataIndex: "qty",
      render: (text, record) => (
        record.isEditing ? (
          <input
            className="form-control"
            width={100}
            type="number"
            min="1"
            value={record.qty}
            onChange={(e) => handleQtyChange(e, record)}
          />
        ) : (
          text
        )
      ),
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      key: 'actions',
      render: (text, record) => (
        <div>
          {record.isEditing ? (
            <>
              <Link
                onClick={() => saveEdit(record)}
                className="me-2"
              >
                <i data-feather="check" className="feather-check"></i>
              </Link>
              <Link
                onClick={() => cancelEdit(record)}
                className="me-2"
              >
                <i data-feather="x-circle" className="feather-x-circle"></i>
              </Link>
            </>
          ) : (
            <Link
              onClick={() => enableEditing(record)}
              className="me-2"
            >
              <i data-feather="download" className="feather-edit"></i>
            </Link>
          )}
          <Link
            className="me-2"
            onClick={() => {
              const newData = addData.filter((data) => data.product._id !== record.product._id)
              setAddData(newData)
            }}
          >
            <i data-feather="trash" className="feather-trash"></i>
          </Link>
        </div>

      )
    },
  ]

  const columnForIssuance = [
    {
      title: 'Job No',
      dataIndex: 'jobNo',
      key: 'jobNo',
      width: "100px"
    },
    {
      title: 'Issuance No',
      dataIndex: '_id',
      key: '_id',
      render: (_id) => <Tag color='cyan'>{_id}</Tag>,
      width: "200px"
    },
    {
      title: 'Items',
      dataIndex: 'items',
      key: 'items',
      render: (items) => (
        items.map((item, index) => (
          <Card key={index} size="small" bordered={false} style={{ marginBottom: '4px', background: '#f0f2f5' }}>
            <Tag color='blue'>{item.productName}</Tag>  quantity: <Tag color='green'>{item.qty}</Tag>
          </Card>
        ))
      ),
      width: "700px"
    },
    {
      title: 'Action',
      key: 'action',
      render: (text, record) => (
        <div>
           {!hasJobRightWithedel && (
                  <Popconfirm
                  title="Delete the GRN"
                  description="Are you sure to delete this GRN?"
                  onConfirm={() => {
                    deleteIssuanceMethod({
                      setIsDeleted,
                      id: record._id
                    })
                  }}
                  onCancel={() => {
                    console.log("c")
                  }}
                  okText="Yes"
                  cancelText="No"
                >
                  <Link
                    className="me-2"
                  >
                    <i data-feather="trash" className="feather-trash"></i>
                  </Link>
                </Popconfirm>
             )}

        </div>
      ),
    }
  ]

  const handleReset = () => {
    setAddData([])
  }

  const handleSubmit = () => {
    if (addData.length === 0) {
      showWarning("at least need one product!!")
      return
    }
    const submitData = {
      items: addData.map((item) => {
        return {
          product: item.product._id,
          qty: item.qty
        }
      }),
      job: searchJob._id,
      id: searchJob?.issuance?._id
    }
    createIssuanceMethod({
      data: submitData,
      setLoading: setLoading2,
      setSuccess
    })
  }

  const showSuccess = (str) => {
    return Swal.fire({
      title: "Good job!",
      text: str,
      icon: "success"
    });
  }
  const showWarning = (str) => {
    return Swal.fire({
      title: "Error",
      text: str,
      icon: "error",
    });
  }
  const handleTableChange = (pagination) => {
    getAllIssuancesMethod({
      setIssuanceData,
      setLoading: setLoading3,
      params: { page: pagination.current, limit: pagination.pageSize },
      search,
      startDate: selectedRange[0].format("YYYY-MM-DD"),
      endDate: selectedRange[1].format("YYYY-MM-DD")
    });
  };

  const onChange = (dates) => {
    if (dates === null) {
      setSelectedRange([startOfCurrentMonth, endOfCurrentMonth])
      return
    }
    setSelectedRange(dates);
  };

  useEffect(() => {
    if (searchJob) {
      const flattenedData = searchJob.jobTypesInfo.map((item, index) => ({
        key: index,
        type: item.jobType.type,
        measurements: item.measurements,
        qty: item.qty,
        styles: item.styles,
        bodyTypes: item.bodyTypes
      }));
      setTableData(flattenedData);
      if (searchJob.issuance) {
        setAddData(searchJob.issuance.items)
      }
    }
  }, [searchJob])

  useEffect(() => {
    const initialCalls = async () => {
      getAllProducts({ setData: setProducts })
    }
    initialCalls()
  }, [])

  useEffect(() => {
    if (success) {
      handleCancel()
      showSuccess("You Successfully Saved Issuance!")
      getAllIssuancesMethod({
        setIssuanceData,
        setLoading: setLoading3,
        params: { page: issuanceData.page, limit: issuanceData.limit },
        search,
        startDate: selectedRange[0].format("YYYY-MM-DD"),
        endDate: selectedRange[1].format("YYYY-MM-DD")
      });
    }
    if (isDeleted) {
      showSuccess("You Successfully Deleted Issuance!")
      getAllIssuancesMethod({
        setIssuanceData,
        setLoading: setLoading3,
        params: { page: issuanceData.page, limit: issuanceData.limit },
        search,
        startDate: selectedRange[0].format("YYYY-MM-DD"),
        endDate: selectedRange[1].format("YYYY-MM-DD")
      });
    }
  }, [success, isDeleted])
  useEffect(() => {
    const handler = setTimeout(() => {
      getAllIssuancesMethod({
        setIssuanceData,
        setLoading: setLoading3,
        params: { page: issuanceData.page, limit: issuanceData.limit },
        search,
        startDate: selectedRange[0].format("YYYY-MM-DD"),
        endDate: selectedRange[1].format("YYYY-MM-DD")
      });
    }, 600);

    return () => {
      clearTimeout(handler);
    };
  }, [search, selectedRange]);
  return (
    <div className="page-wrapper">
      <div className="content">
        <div className="page-header">
          <div className="add-item d-flex">
            <div className="page-title">
              <h4>Issuance</h4>
              <h6>Manage your Issuance</h6>
            </div>
          </div>
          <div className="page-btn" onClick={() => {
            setIsModalOpen(true)
          }}>
            {!hasJobRightWithAdd && (
                        <a className="btn btn-added">
                            <PlusCircle className="me-2" />
                            Add Or Edit Issuance
                        </a>
             )}

         </div>
        </div>

        <div className="card table-list-card">
          <div className="card-body">
            <div className="table-top">
              <div className="search-set">
                <div className="search-input">
                  <input
                    type="search"
                    className="form-control"
                    placeholder="Search"
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                  />
                  <Link className="btn btn-searchset">
                    <i data-feather="search" className="feather-search" />
                  </Link>
                </div>
                <RangePicker onChange={onChange} value={selectedRange} format="YYYY-MM-DD" />

              </div>

            </div>
            <div className="table-responsive">
              <Table
                loading={loading3}
                columns={columnForIssuance}
                dataSource={issuanceData.data}
                pagination={{
                  current: issuanceData.page,
                  pageSize: issuanceData.limit,
                  total: issuanceData.total
                }}
                rowKey="value"
                onChange={handleTableChange}
              />
            </div>
          </div>
        </div>
      </div>
      <Modal
        zIndex={200000}
        open={isModalOpen}
        onOk={handleOk}
        width="75vw"
        onCancel={handleCancel}
        footer={null}
        maskClosable={false}
      >
        <div className="modal-content">
          <div className="modal-header border-0 custom-modal-header">
            {/* {
                            viewGRN ? <div className="page-title">
                                <h4>{"View Issuance"}</h4>
                            </div> :
                                <div className="page-title">
                                    <h4>{editGRN ? "Edit GRN" : "Create GRN"}</h4>
                                    <p className="mb-0">Fill in the required fields to create a GRN</p>
                                </div>
                        } */}
            <div className="page-title">
              <h4>{"Customize Issuance"}</h4>
              <p className="mb-0">Fill in the required fields to create a Issuance</p>
            </div>
          </div>
          <div className="modal-body custom-modal-body">
            <div style={{
              display: "flex",
              //justifyContent: "space-between",
              alignItems: "center",
              gap: "10px"
            }}>
              <div className="col-lg-4 col-sm-6 col-12">
                <div className="input-blocks">
                  <label>Type Job Number</label>
                  <input
                    type="text"
                    className="form-control"
                    value={jobNo}
                    onChange={(e) => {
                      const value = e.target.value;
                      if (value.length <= 6 && /^\d*$/.test(value)) {
                        setJobNo(value);
                      } else {
                        showWarning("Invoice Number should be 6 digits and numeric");
                      }
                    }}
                  //disabled={loading}
                  />
                </div>
              </div>
              <button
                //disabled={loading} 
                className="btn btn-primary" style={{ marginTop: "5px" }} onClick={() => findJobByJobno(jobNo)}>Search</button>
            </div>

          </div>
          {
            loading ? <Spin size='large' /> : searchJob ? <>
              <Table columns={columns} dataSource={tableData} />
              <div className="row">
                <div style={{
                  display: "flex",
                  flexDirection: "row",
                  gap: "5px",
                  alignItems: "center"
                }} >
                  <div className="col-lg-4 col-md-4 col-sm-4">
                    <div className="input-blocks">
                      <label>Product</label>
                      <select
                        className="form-control"
                        value={selectedProduct}
                        onChange={(e) => setSelectedProduct(e.target.value)}
                      >
                        <option value={""}>Select a Product</option>
                        {
                          products.map((product) => (
                            <option key={product._id} value={product._id}>{product.productName}</option>
                          ))
                        }
                      </select>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-4 col-sm-4">
                    <div className="input-blocks">
                      <label>Qty</label>
                      <input
                        type="number"
                        className="form-control"
                        value={qty}
                        onChange={(e) => {
                          if (parseFloat(e.target.value) < 0) {
                            showWarning("cant be negative")
                            return
                          }
                          setQty(e.target.value)
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-4 col-sm-4 mt-1">
                    <button className="btn btn-primary"
                      disabled={!selectedProduct || !qty || qty <= 0}
                      onClick={() => {
                        const isProductExistsTable = addData.find((data) => data.product._id === selectedProduct)
                        if (isProductExistsTable) {
                          showWarning("Product already exists in the table")
                          return
                        }
                        setAddData([...addData, {
                          product: products.find((product) => product._id === selectedProduct),
                          qty: qty
                        }])
                        setQty(0)
                        setSelectedProduct("")
                      }}
                    >Add To Table</button>
                  </div>
                </div>
                <Table columns={columnsForAddData} dataSource={addData} rowKey="value" />
              </div>
              <div className="" style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center"
              }}>
                <button
                  type="button"
                  className="btn btn-cancel"
                  onClick={handleCancel}
                >
                  Close
                </button>
                <div className="">
                  <button
                    type="button"
                    className="btn btn-reset me-2"
                    onClick={() => {
                      handleReset()
                    }}
                  >
                    Reset
                  </button>
                  <button type='button'
                    onClick={handleSubmit}
                    className="btn btn-submit ml-0"
                    disabled={loading2 || addData.some((item) => item?.isEditing === true)}
                  >
                    {loading2 ? <Spin size='small' /> : "Save"}
                  </button>
                </div>
              </div>
            </> : <div style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%"
            }}>
              <Empty
                description={
                  <span>
                    {
                      "No Data Found"
                    }
                  </span>
                }
              />
            </div>
          }
        </div>
      </Modal>
    </div>
  );
};

export default Issuance;
