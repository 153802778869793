import axios from "axios";
import { BASE_URL } from "../environment";
import Swal from "sweetalert2";


const showWarning = (str) => {
    return Swal.fire({
        title: "Error",
        text: str,
        icon: "error",
    });
}

export const login = ({ email, password }) => {
    const response = axios.post(`/user/login`, {
        email,
        password,
    });
    return response;
};

export const register = ({ name, email, password, confirmPassword }) => {
    return axios.post(`http://${BASE_URL}:8000/api/user/register`, {
        name,
        email,
        password,
        confirmPassword,
    });
};
export const createUser = ({
    name,
    phone,
    email,
    role,
    password,
    confirmPassword,
    description,
}) => {
    return axios.post(`http://${BASE_URL}:8000/api/user/register`, {
        name,
        phone,
        email,
        role,
        password,
        confirmPassword,
        description,
    });
};

//create role
export const createRole = async (roleName) => {
    try {
        const response = await axios.post(
            `http://${BASE_URL}:8000/api/rolesPermissions`,
            {
                role: roleName,
            }
        );
        return response.data;
    } catch (error) {
        throw error.response.data;
    }
};
//create  role

//update role
export const updateRole = async (roleId, newRoleName) => {
    try {
        const response = await axios.put(
            `http://${BASE_URL}:8000/api/rolesPermissions/${roleId}`,
            newRoleName
        );
        return response.data;
    } catch (error) {
        throw new Error(error.response.data.message || "Failed to update role");
    }
};
//update role

//delete role
export const deleteRole = async (roleId) => {
    try {
        const response = await axios.delete(
            `http://${BASE_URL}:8000/api/rolesPermissions/${roleId}`
        );
        console.log(response.data, "response deleteRole API");
        return response.data;
    } catch (error) {
        throw new Error(error.response.data.message || "Failed to delete role");
    }
};
//delete role

export const getRoles = async () => {
    const response = await fetch(`http://${BASE_URL}:8000/api/rolesPermissions`);
    if (!response.ok) {
        throw new Error("Failed to fetch roles");
    }
    return await response.json();
};

//get permission by id
export const getPermissionsByRoleId = async (roleId) => {
    try {
        const response = await axios.get(
            `http://${BASE_URL}:8000/api/permissions/${roleId}`
        );

        return response.data;
    } catch (error) {
        throw new Error(
            error.response.data.message || "Failed to fetch permissions"
        );
    }
};

//save the profile picture

const uploadProfilePic = async (userId, image) => {
    const formData = new FormData();
    formData.append("profilePic", image);

    try {
        await axios.post(
            `http://${BASE_URL}:8000/api/user/${userId}/uploadProfilePic`,
            formData,
            {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            }
        );

        return { success: true };
    } catch (error) {
        console.error("Error updating profile picture:", error);
        return { success: false, error: "Failed to update profile picture" };


    }
};

export { uploadProfilePic };

// Lahiru Rasanjaya Front End ApiServices


export const createCustomer = ({
    customerName,
    customerEmail,
    customerPhone,
    customerAddress,
    customerCity,
    customerCountry,
    customerDescription,
    customerImage,
}) => {
    return axios.post(`/customers`, {
        customerName,
        customerEmail,
        customerPhone,
        customerAddress,
        customerCity,
        customerCountry,
        customerDescription,
        customerImage,
    });
};

export const updateCustomer = async (customerId, customerData) => {
    return axios.put(
        `/customers/${customerId}`,
        customerData
    );


};


export const getCustomer = async (customerId) => {
    return axios.get(`/customers/${customerId}`);
};


export const createSupplier = ({
    supplierName,
    supplierEmail,
    supplierPhone,
    supplierAddress,
    supplierCity,
    supplierCountry,
    supplierDescription,
    supplierImage,
}) => {
    return axios.post(`http://${BASE_URL}:8000/api/suppliers`, {
        supplierName,
        supplierEmail,
        supplierPhone,
        supplierAddress,
        supplierCity,
        supplierCountry,
        supplierDescription,
        supplierImage,
    });
};

export const updateSupplier = async (supplierId, supplierData) => {

    return axios.put(
        `http://${BASE_URL}:8000/api/suppliers/${supplierId}`,
        supplierData
    );


};

export const getSupplier = async (supplierId) => {
    return axios.get(`http://${BASE_URL}:8000/api/suppliers/${supplierId}`);

};


export const createStore = ({
    storeName,
    storeEmail,
    storePhone,
    storeAddress,
    storeCity,
    storeCountry,
    storeDescription,
    storeImage,
}) => {
    return axios.post(`http://${BASE_URL}:8000/api/stores`, {
        storeName,
        storeEmail,
        storePhone,
        storeAddress,
        storeCity,
        storeCountry,
        storeDescription,
        storeImage,
    });

};


export const updateStore = async (storeId, storeData) => {
    return axios.put(`http://${BASE_URL}:8000/api/stores/${storeId}`, storeData);
};

export const getStore = async (storeId) => {
    return axios.get(`http://${BASE_URL}:8000/api/stores/${storeId}`);

};
export const createWareHouse = ({
    wareHouseName,
    wareHouseContactPerson,
    wareHousePhone,
    wareHouseWorkPhone,
    wareHouseEmail,
    wareHouseAddress1,
    wareHouseAddress2,
    wareHouseStatus,
    wareHouseCountry,
    wareHouseState,
    wareHouseCity,
    wareHouseZipcode,
    wareHouseDate,
}) => {
    return axios.post(`http://${BASE_URL}t:8000/api/wareHouses`, {
        wareHouseName,
        wareHouseContactPerson,
        wareHousePhone,
        wareHouseWorkPhone,
        wareHouseEmail,
        wareHouseAddress1,
        wareHouseAddress2,
        wareHouseStatus,
        wareHouseCountry,
        wareHouseState,
        wareHouseCity,
        wareHouseZipcode,
        wareHouseDate,
    });
};

export const updateWareHouse = async (wareHouseId, wareHouseData) => {
    return axios.put(
        `http://${BASE_URL}:8000/api/wareHouses/${wareHouseId}`,
        wareHouseData
    );

};

export const getWareHouse = async (wareHouseId) => {
    return axios.get(`http://${BASE_URL}:8000/api/wareHouses/${wareHouseId}`);

};

export const createExpenseCategory = ({
    expenseCategoryName,
    expenseCategoryDescription,
}) => {
    return axios.post(`http://${BASE_URL}:8000/api/expenseCategories`, {
        expenseCategoryName,
        expenseCategoryDescription,
    });
};

export const updateExpenseCategory = async (expenseCategoryId, storeData) => {
    return axios.put(
        `http://${BASE_URL}t:8000/api/expenseCategories/${expenseCategoryId}`,
        storeData
    );


};

export const getExpenseCategory = async (expenseCategoryId) => {
    return axios.get(
        `http://${BASE_URL}:8000/api/expenseCategories/${expenseCategoryId}`
    );


};

export const createExpense = ({
    expenseCategory,
    expenseDate,
    expenseAmount,
    expenseState,
    expenseFor,
    expenseDescription,
}) => {
    return axios.post(`http://${BASE_URL}:8000/api/expenses`, {
        expenseCategory,
        expenseDate,
        expenseAmount,
        expenseState,
        expenseFor,
        expenseDescription,
    });
};

export const updateExpense = async (expenseId, expenseData) => {

    return axios.put(
        `http://${BASE_URL}:8000/api/expenses/${expenseId}`,
        expenseData
    );


};

export const getExpense = async (expenseId) => {
    return axios.get(`http://${BASE_URL}:8000/api/expenses/${expenseId}`);


};


export const createCoupon = ({
    couponName,
    couponCode,
    couponType,
    couponDiscount,
    couponLimit,
    couponStartDate,
    couponEndDate,
    oncePerCustomer,
    products,
    couponState,
}) => {
    return axios.post(`http://${BASE_URL}:8000/api/coupons`, {
        couponName,
        couponCode,
        couponType,
        couponDiscount,
        couponLimit,
        couponStartDate,
        couponEndDate,
        oncePerCustomer,
        products,
        couponState,
    });
};

export const updateCoupon = async (couponId, couponData) => {
    return axios.put(`http://${BASE_URL}:8000/api/coupons/${couponId}`, couponData);
};

export const getCoupon = async (wareHouseId) => {
    return axios.get(`http://${BASE_URL}:8000/api/coupons/${wareHouseId}`);


};

export const createProduct = (formData) => {
    return axios.post(`/products`, formData);
};
export const updateProduct = async (productId, formData) => {
    return axios.put(`/products/${productId}`, formData);
};
export const getProducts = async (search, page = 1, limit = 10) => {
    return axios.get(`/products`, {
        params: {
            search,
            page,
            limit,
        },
    });
};


export const getProduct = async (productId) => {
    return axios.get(`http://${BASE_URL}:8000/api/products/${productId}`);
};


export const createAdminAttendance = (formData) => {
    return axios.post(`http://${BASE_URL}:8000/api/adminAtendances`, formData, {});
};

export const updateAdminAttendance = async (adminAttendanceId, adminAttendanceData) => {

    return axios.put(`http://${BASE_URL}:8000/api/adminAtendances/${adminAttendanceId}`, adminAttendanceData);
};
export const getAdminAttendance = async (adminAttendanceId) => {
    return axios.get(`http://${BASE_URL}:8000/api/adminAtendances/${adminAttendanceId}`);
};

// Stock
export const createStock = (formData) => {
    return axios.post(`http://${BASE_URL}:8000/api/stocks`, formData, {});
};

export const updateStock = async (stockId, stockData) => {

    return axios.put(`http://${BASE_URL}:8000/api/stocks/${stockId}`, stockData);
};

export const getStock = async (stockId) => {
    return axios.get(`http://${BASE_URL}:8000/api/stocks/${stockId}`);
};

export const createStockAdjustment = (formData) => {
    return axios.post(`http://${BASE_URL}:8000/api/stockAdjustments`, formData, {});
};

//Stock Adjustment
export const updateStockAdjustment = async (stockAdjustmentId, stockData) => {

    return axios.put(`http://${BASE_URL}:8000/api/stockAdjustments/${stockAdjustmentId}`, stockData);
};

export const getStockAdjustment = async (stockAdjustmentId) => {
    return axios.get(`http://${BASE_URL}:8000/api/stockAdjustments/${stockAdjustmentId}`);
};

export const createStockTransfer = (formData) => {
    return axios.post(`http://${BASE_URL}:8000/api/stockTransfers`, formData, {});
};

// stock Transfer
export const updateStockTransfer = async (stockTransferId, stockData) => {

    return axios.put(`http://${BASE_URL}:8000/api/stockTransfers/${stockTransferId}`, stockData);
};

export const getStockTransfer = async (stockTransferId) => {
    return axios.get(`http://${BASE_URL}:8000/api/stockTransfers/${stockTransferId}`);
};

//update user
export const updateUser = async (userId, userData) => {
    try {
        const response = await axios.put(
            `/user/updateUser/${userId}`,
            userData
        );


        if (response.status === 200) {
            return response.data;
        } else {
            throw new Error("Failed to update user");


        }
    } catch (error) {
        throw new Error("Failed to update user: " + error.message);


    }
};

//Get user detail using ID
export const getUserById = async (userId) => {
    const response = await fetch(`/user/${userId}`);
    if (!response.ok) {
        throw new Error("Failed to fetch user details");


    }
    return response.json();

}

//delete user
export const deleteUser = async (userId) => {
    const response = await axios.delete(
        `/user/delete/${userId}`
    );


    return response.data;
};

//brands
export const getAllBrands = async (search) => {
    return await axios.get('/brand?search=' + search);
};

export const createBrand = async (brandData) => {
    return await axios.post('/brand/create', brandData);
};

export const editBrand = async (id, brandData) => {
    return await axios.put(`/brand/${id}`, brandData);
};

export const deleteBrand = async (id) => {
    return await axios.delete(`/brand/${id}`);
};

export const getBrandById = async (id) => {
    try {
        const response = await axios.get(`/brand/${id}`);
        return response.data;
    } catch (error) {
        showWarning(error.response.data.message);
    }
};

//variant
export const createVariant = async (variantData) => {
    try {
        const response = await axios.post(
            "/variant/",
            variantData
        );

        return response.data;
    } catch (error) {
        throw new Error(error.response.data.message || "Failed to create brand");
    }
};

export const editVariant = async (id, updatedVariantData) => {
    try {
        const response = await axios.put(
            `/variant/${id}`,
            updatedVariantData
        );

        return response.data;
    } catch (error) {
        throw new Error(error.response.data.message || "Failed to update variant");
    }
};

export const deleteVariant = async (id) => {
    try {
        const response = await axios.delete(
            `/variant/${id}`
        );
        return response.data;
    } catch (error) {
        throw new Error(error.response.data.message);
    }
};


export const searchVariant = async (query) => {
    try {
        const response = await axios.get(
            `/variant/search?name=${query}`
        );
        return response.data;
    } catch (error) {
        throw new Error(error.response.data.message || "Failed to search variant");
    }
};

//variant

//unit

export const createUnit = async (unitData) => {
    return await axios.post(`/unit`, unitData);
};

export const updateUnit = async (id, updatedUnitData) => {
    return await axios.put(`/unit/${id}`, updatedUnitData);
};

export const getUnits = async (search) => {
    return await axios.get('/unit?search=' + search);
};

export const deleteUnit = async (id) => {
    return await axios.delete(`/unit/${id}`);
};

export const searchUnit = async (query) => {
    try {
        const response = await axios.get(
            `/unit/search?name=${query}`
        );

        return response.data;
    } catch (error) {
        throw new Error(`Failed to search unit: ${error.message}`);
    }
};

// --------------------------Category API---------------------------------------------------------------------
//Add Category
export const addCategory = async (data) => {
    return await axios.post("category-list/addcategory", data);
};

// Get Category
export const getCategory = async (search) => {
    return await axios.get("/category-list?search=" + search);

};

// Delete Category
export const deleteCategory = async (Id) => {
    return await axios.delete(`/category-list/${Id}`);
};

// Update Category
export const updateCategory = async (data, id) => {
    return await axios.put(`category-list/${id}`, data);
};

export const categorySearch = async (query) => {
    try {
        const response = await axios.get(
            `/category-list/search?name=${query}`
        );

        return response.data;
    } catch (error) {
        throw new Error(`Failed to search unit: ${error.message}`);
    }
};
//------------------------------------Sub Category----------------------------------------------------------
//Roshan
//Add Sub Category
export const addsubCategory = async (categoryData) => {
    return await axios.post("/sub-categories/create/123",
        categoryData
    );
};

export const getSubCategoriesByCategory = async (id) => {
    return await axios.get("/sub-categories/by-category/" + id
    );
};


// Update Sub Category
export const updatesubCategory = async (id, subcategoryData) => {
    try {
        const response = await axios.put(
            `/sub-categories/${id}`,

            `/sub-categories/${id}`,

            subcategoryData
        );
        console.log("Subcategory Response updated", response);
        if (response.status === 200) {
            return response.data.subcategory;
        } else {
            throw new Error("Failed to update subcategory");
        }
    } catch (error) {
        throw new Error("Failed to update subcategory: " + error.message);
    }
};

// Delete Sub  Category
export const deleteSubCategory = async (id) => {
    try {
        const response = await axios.delete(
            `/sub-categories/${id}`
        );
        console.log(response.data, "response delete sub category API");
        return response.data;
    } catch (error) {
        throw new Error(
            error.response.data.message || "Failed to sub delete category"
        );
    }
};

// EDit Sub Category

//-------------------------------------------------------Warranty------------------------------------------------------------------------------
// Add Warranty
export const addWarranty = async ({ name, description, duration, periods }) => {
    try {
        const response = await axios.post("/warranty", {
            name,
            description,
            duration,
            periods,
        });
        return response.data;
    } catch (error) {
        throw error.response.data;
    }
};

// Get Warranty
export const getWarranty = async () => {
    const response = await fetch("/warranty");
    if (!response.ok) {
        throw new Error("Failed to fetch Warranty");
    }
    return await response.json();
};

export const getWarrantyByID = async (id) => {
    const response = await fetch(`/warranty/${id}`);
    if (!response.ok) {
        throw new Error("Failed to fetch Warranty");
    }
    return await response.json();
};

export const updatewarranty = async (id, warrantyData) => {
    try {
        const response = await axios.put(
            `/warranty/${id}`,
            warrantyData
        );
        console.log("Subcategory Response updated", response);
        if (response.status === 200) {
            return response.data.subcategory;
        } else {
            throw new Error("Failed to update warranty");
        }
    } catch (error) {
        throw new Error("Failed to update warranty: " + error.message);
    }
};

// Delete Warranty
export const deleteWarranty = async (id) => {
    try {
        const response = await axios.delete(
            `/warranty/${id}`
        );
        console.log(response.data, "response delete warranty API");
        return response.data;
    } catch (error) {
        throw new Error(error.response.data.message || "Failed to delete category");
    }
};

//department
// crreate new department
export const createDepartment = async (department) => {
    try {
        const response = await axios.post(
            `http://${BASE_URL}:8000/api/departments`,
            department
        );

        return response.data;
    } catch (error) {
        throw new Error(
            error.response.data.message || "Failed to create department"
        );
    }
};

//Get department detail using ID
export const getDepartmentById = async (departmentId) => {
    const response = await fetch(
        `http://${BASE_URL}:8000/api/departments/${departmentId}`
    );

    if (!response.ok) {
        throw new Error("Failed to fetch department details");
    }
    return response.json();
};

//Update department detail using ID
export const editDepartment = async (departmentId, departmentData) => {
    try {
        const response = await axios.put(
            `http://${BASE_URL}:8000/api/departments/${departmentId}`,
            departmentData
        );

        return response.data;
    } catch (error) {
        throw new Error(
            error.response?.data?.message || "Failed to edit department"
        );
    }
};

//designation
export const createDesignation = async (designation) => {
    try {
        const response = await axios.post(
            `http://${BASE_URL}:8000/api/designations`,
            designation
        );

        return response.data;
    } catch (error) {
        throw new Error(
            error.response.data.message || "Failed to create designation"
        );
    }
};

//Get designation detail using ID
export const getDesignationById = async (designationId) => {
    const response = await fetch(
        `http://${BASE_URL}:8000/api/designations/${designationId}`
    );

    if (!response.ok) {
        throw new Error("Failed to fetch designation details");
    }
    return response.json();
};

//Update designation detail using ID
export const editDesignation = async (designationId, designationData) => {
    try {
        const response = await axios.put(
            `http://${BASE_URL}:8000/api/designations/${designationId}`,
            designationData
        );

        return response.data;
    } catch (error) {
        throw new Error(
            error.response?.data?.message || "Failed to edit designation"
        );
    }
};

//shifts
export const createShift = async (shift) => {
    try {
        const response = await axios.post(
            `http://${BASE_URL}:8000/api/shifts`,
            shift
        );

        return response.data;
    } catch (error) {
        throw new Error(error.response.data.message || "Failed to create shift");
    }
};

//Get shift detail using ID
export const getShiftById = async (shiftId) => {
    const response = await fetch(`http://${BASE_URL}:8000/api/shifts/${shiftId}`);
    if (!response.ok) {
        throw new Error("Failed to fetch shift details");
    }
    return response.json();
};

//Update shift detail using ID
export const editShift = async (shiftId, shiftData) => {
    try {
        const response = await axios.put(
            `http://${BASE_URL}:8000/api/shifts/${shiftId}`,
            shiftData
        );

        return response.data;
    } catch (error) {
        throw new Error(error.response?.data?.message || "Failed to edit shift");
    }
};

// create new employee
export const createEmployee = async (employee) => {
    try {
        const response = await axios.post(
            `http://${BASE_URL}:8000/api/employees`,
            employee,
            {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            }
        );

        return response.data;
    } catch (error) {
        throw new Error(error.response.data.message || "Failed to create employee");
    }
};

//Get employee detail using ID
export const getEmployeeById = async (employeeId) => {
    const response = await fetch(
        `http://${BASE_URL}:8000/api/employees/${employeeId}`
    );

    if (!response.ok) {
        throw new Error("Failed to fetch employee details");
    }
    return response.json();
};

// update employee

export const updateEmployee = async (employeeId, employeeData) => {
    try {
        const response = await axios.put(
            `http://${BASE_URL}:8000/api/employees/${employeeId}`,
            employeeData,
            {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            }
        );
        return response.data;
    } catch (error) {
        throw new Error(error.response.data.message || "Failed to update employee");
    }
};
export const addBarcode = async ({ wareHouse, store }) => {
    try {
        const response = await axios.post("/barcode", {
            wareHouse,
            store,
        });
        return response.data;
    } catch (error) {
        throw error.response.data;
    }
};

export const qrCode = async ({ wareHouse, store }) => {
    try {
        const response = await axios.post("/qrcode", {
            wareHouse,
            store,
        });
        return response.data;
    } catch (error) {
        throw error.response.data;
    }
};

export const fetchBarcode = async (barcodeId) => {
    try {
        const response = await axios.get(
            `/barcode/${barcodeId}`
        );
        return response.json();
    } catch (error) {
        throw new Error("Failed to fetch barcode details");
    }
};

/////////////////////////// new ends  ///////////////////////////

export const getAllCustomers = async ({ setLoading }) => {
    try {
        console.log("Set Loading", setLoading)
        setLoading !== undefined ? setLoading(true) : "";
        const response = await axios.get("/customers");
        setLoading !== undefined ? setLoading(false) : "";
        return response;
    } catch (error) {
        setLoading !== undefined ? setLoading(false) : "";
        showWarning("Failed to fetch customers");
    }
}

//Create Job Type API

export const creteJobTypeMethod = async ({
    type,
    styles,
    handleCancel,
    handleReset,
    showSuccess,
    handleJobTypeAdding,
    typeId,
    price,
    setLoading
}) => {
    try {
        console.log(JSON.parse(localStorage.getItem('user')).data._id)
        const userId = JSON.parse(localStorage.getItem('user')).data._id
        setLoading(true)
        await axios.post("/job-types", { type, styles, userId, typeId, price })
            .then((response) => {
                handleCancel();
                handleReset();
                setLoading(false)
                showSuccess()
                handleJobTypeAdding()
                console.log(response)
            }).catch((error) => {
                setLoading(false)
                console.log(error)
                if (error.response.status === 400) {
                    showWarning(error.response.data.error || error.response.data.message)
                    return
                }
                showWarning("Failed to create job type");
            })
    } catch (error) {
        console.log(error)
        showWarning("Failed to create job type");
    }
}




export const getAllJobTypeMethod = async ({ search, setLoading }) => {
    try {
        const response = await axios.get("/job-types?user=" + JSON.parse(localStorage.getItem('user')).data._id + "&search=" + search);
        setLoading(false)
        return response.data
    } catch (error) {
        setLoading(false)
        console.log(error)
        showWarning('Failed to fetch job types');
    }
}

export const getJobByJobNoMethod = async ({ jobNo, setLoading, setData }) => {
    try {
        setLoading(true)
        const response = await axios.get("/get-job-no/" + jobNo);
        console.log("response jobs",response)
        setLoading(false)
        setData(response.data)
    } catch (error) {
        setLoading(false)
        if (error.response.status === 404) {
            showWarning(error.response.data.error + " :" + jobNo)
            return
        }
        showWarning('Failed to fetch job');
    }
}

export const createJobMethod = async ({ handleCancel, data, showSuccess, handleReset, handleJobAdded,setLoading }) => {
    try {
        setLoading(true)
        const userId = JSON.parse(localStorage.getItem('user')).data._id;
        await axios.post("/jobs", { ...data, user: userId }).then((response) => {
            handleCancel();
            showSuccess()
            handleReset();
            handleJobAdded()
            setLoading(false)
            console.log(response)
        }).catch((error) => {
            console.log(error)
            setLoading(false)
            showWarning("Failed to create job");
        })
    } catch (error) {
        console.log(error)
        showWarning("Failed to create job");
    }
}

export const getAllJobMethod = async ({ search, params, setLoading, startDate, endDate }) => {
    try {
        setLoading(true)
        const response = await axios.get("/jobs?user="
            + JSON.parse(localStorage.getItem('user')).data._id
            + "&search=" + search
            + "&page=" + params.page
            + "&limit=" + params.limit
            + "&startDate=" + startDate
            + "&endDate=" + endDate
        );
        setLoading(false)
        return response.data
    } catch (error) {
        setLoading(false)
        console.log(error)
        showWarning("Failed to fetch jobs")
    }
}

export const jobStatusChangeMethod = async ({
    jobId,
    jobStatus,
    setIsStatusChanged,
    handleCancel,
    showSuccessChangeStatus
}) => {
    try {
        await axios.put(`/job-status`, { status: jobStatus, id: jobId }).then((response) => {
            console.log(response)
            setIsStatusChanged(true)
            handleCancel()
            showSuccessChangeStatus()
            handleCancel()
            setTimeout(() => {
                setIsStatusChanged(false)
            }, 1000)
        }).catch((error) => {
            console.log(error)
            showWarning("Failed to change job status");
        })

    } catch (error) {
        console.log(error)
        showWarning("Failed to change job status");
    }
}

export const deleteJobMethod = async ({ jobId, setIsItemDeleted, showSuccessDeleted }) => {
    try {
        await axios.delete("/jobs/" + jobId).then(() => {
            setIsItemDeleted(true)
            showSuccessDeleted()
            setTimeout(() => {
                setIsItemDeleted(false)
            }, 1000)
        }).catch((err) => {
            if (err.response.status === 400) {
                showWarning(err.response.data.error)
                return
            }
            showWarning("Failed to delete the job")
        })
    } catch (error) {
        console.log(error)
        showWarning("Failed to delete the job")
    }
}

export const getInvoiceNumMethod = async () => {
    try {
        const userId = JSON.parse(localStorage.getItem('user')).data._id;
        const response = await axios.get("/invoice-number?user=" + userId);
        return response.data
    } catch (error) {
        console.log(error)
        showWarning('Failed to fetch invoice number');
    }
}
export const getInvoiceByNo = async (invoiceNo) => {
    try {

        const response = await axios.get("/invoice/by-no/" + invoiceNo);
        return response.data
    } catch (error) {
        console.log(error)
        showWarning('Failed to fetch invoice number');
    }
}
export const createInvoice = async ({ data, setLoading, handleCancel, setIsInvoiceCreated, showSuccess }) => {
    try {
        setLoading(true)
        const userId = JSON.parse(localStorage.getItem('user')).data._id;
        await axios.post("/invoice", { ...data, user: userId }).then(() => {
            setLoading(false)
            handleCancel()
            setIsInvoiceCreated(true)
            showSuccess("successfully save invoice!!")
            setTimeout(() => {
                setIsInvoiceCreated(false)
            }, 1000)
        }).catch((error) => {
            setLoading(false)
            console.log(error)
            showWarning("Failed to create invoice");
        })
    } catch (error) {
        console.log(error)
        showWarning("Failed to create invoice");
    }
}

export const getAllInvoiceMethod = async ({ setInvoiceData, setLoading, params, search, startDate, endDate }) => {
    try {
        setLoading(true)
        const userId = JSON.parse(localStorage.getItem('user')).data._id;
        await axios.get("/invoices?user="
            + userId + "&page="
            + params.page
            + "&limit="
            + params.limit
            + "&search="
            + search
            + "&startDate="
            + startDate
            + "&endDate="
            + endDate
        ).then((response) => {
            setLoading(false)
            setInvoiceData(response.data)
        }).catch((error) => {
            setLoading(false)
            console.log(error)
            showWarning('Failed to fetch invoices');
        })
    } catch (error) {
        console.log(error)
        showWarning('Failed to fetch invoices');
    }
}

export const deleteInvoiceMethod = async ({ invoiceId, setIsItemDeleted, showSuccess }) => {
    try {
        await axios.delete("/invoice/" + invoiceId).then(() => {
            setIsItemDeleted(true)
            showSuccess("successfully deleted invoice!!")
            setTimeout(() => {
                setIsItemDeleted(false)
            }, 1000)
        }).catch(() => {
            showWarning("Failed to delete the invoice")
        })
    } catch (error) {
        console.log(error)
        showWarning("Failed to delete the invoice")
    }
}

export const createPaymentMethod = async ({ data, setLoading, handleCancel, showSuccess, setIsPaymentCreated }) => {
    try {
        setLoading(true)
        await axios.post("/payment", { ...data }).then(() => {
            setLoading(false)
            handleCancel();
            setIsPaymentCreated(true)
            setTimeout(() => {
                setIsPaymentCreated(false)
            }, 1000)
            showSuccess("successfully create payment!!")
        }).catch((error) => {
            setLoading(false)
            console.log(error)
            showWarning("Failed to create payment");
        })
    } catch (error) {
        console.log(error)
        showWarning("Failed to create payment");
    }
};
//Quotation
export const getQuotationNumMethod = async () => {
    try {
        const userId = JSON.parse(localStorage.getItem("user")).data._id;
        const response = await axios.get(
            "/quotation-number?user=" + userId
        );
        return response.data;
    } catch (error) {
        console.log(error);
        showWarning("Failed to fetch invoice number");
    }
};

export const createQuotation = async ({
    data,
    setLoading,
    handleCancel,
    setIsQuotationCreated,
    showSuccess,
}) => {
    try {
        setLoading(true);
        const userId = JSON.parse(localStorage.getItem("user")).data._id;
        await axios
            .post("/quotation", { ...data, user: userId })
            .then(() => {
                setLoading(false);
                handleCancel();
                setIsQuotationCreated(true);
                showSuccess("successfully save Quotation!!");
                setTimeout(() => {
                    setIsQuotationCreated(false);
                }, 1000);
            })
            .catch((error) => {
                setLoading(false);
                console.log(error);
                showWarning("Failed to create Quotation");
            });
    } catch (error) {
        console.log(error);
        showWarning("Failed to create Quotation");
    }
};

export const getAllQuotationMethod = async ({
    setQuotationData,
    setLoading,
    params,
    search,
    startDate,
    endDate,
}) => {
    try {
        setLoading(true);
        const userId = JSON.parse(localStorage.getItem("user")).data._id;
        await axios
            .get(
                "/quotations?user=" +
                userId +
                "&page=" +
                params.page +
                "&limit=" +
                params.limit +
                "&search=" +
                search +
                "&startDate=" +
                startDate +
                "&endDate=" +
                endDate
            )
            .then((response) => {
                setLoading(false);
                setQuotationData(response.data);
            })
            .catch((error) => {
                setLoading(false);
                console.log(error);
                showWarning("Failed to fetch invoices");
            });
    } catch (error) {
        console.log(error);
        showWarning("Failed to fetch invoices");
    }
};

export const getAllBodyTypeMethod = async ({ search, setLoading }) => {
    try {
        const response = await axios.get("/body-types?user="
            + JSON.parse(localStorage.getItem('user')).data._id
            + "&search=" + search
        );
        setLoading(false)
        return response.data
    } catch (error) {
        setLoading(false)
        console.log(error)
        showWarning('Failed to fetch job types');
    }
}

export const creteBodyTypeMethod = async ({
    name,
    types,
    handleCancel,
    handleReset,
    showSuccess,
    handleJobTypeAdding,
    typeId,
    setLoading
}) => {
    try {
        const userId = JSON.parse(localStorage.getItem('user')).data._id;
        console.log("typeId", typeId)
        setLoading(true)
        await axios.post("/body-type", { name, types, user: userId, typeId })
            .then((response) => {
                handleCancel();
                handleReset();
                setLoading(false)
                showSuccess()
                handleJobTypeAdding()
                console.log(response)
            }).catch((error) => {
                setLoading(false)
                console.log(error)
                if (error.response.status === 400) {
                    showWarning(error.response.data.error)
                    return
                }
                showWarning("Failed to create job type");
            })
    } catch (error) {
        console.log(error)
        showWarning("Failed to create job type");
    }
}

export const getAllGRNsMethod = async ({ setGRNdata, setLoading, params, search, startDate, endDate }) => {
    try {
        setLoading(true)
        const userId = JSON.parse(localStorage.getItem('user')).data._id;
        await axios.get("/grns?user="
            + userId + "&page="
            + params.page
            + "&limit="
            + params.limit
            + "&search="
            + search
            + "&startDate="
            + startDate
            + "&endDate="
            + endDate
        ).then((response) => {
            setLoading(false)
            setGRNdata(response.data)
        }).catch((error) => {
            setLoading(false)
            console.log(error)
            showWarning('Failed to fetch invoices');
        })
    } catch (error) {
        console.log(error)
        showWarning('Failed to fetch invoices');
    }
}

export const deleteGRN = async ({ id, setDeleted }) => {
    try {
        axios.delete("/grn?id=" + id).then(() => {
            setDeleted(true)
            setTimeout(() => {
                setDeleted(false)
            }, 1000)
        }).catch((err) => {
            if (err.response.status === 400) {
                showWarning(err.response.data.message)
                return
            }
            showWarning("Error occured while deleting GRN")
        })
    } catch (error) {
        showWarning("Error occured while deleting GRN")
    }
}

export const createGRN = async ({ data, setLoading, setSuccess }) => {
    try {
        setLoading(true)
        const userId = JSON.parse(localStorage.getItem('user')).data._id;
        axios.post("/grn", { ...data, user: userId }).then(() => {
            setLoading(false)
            setSuccess(true)
            setTimeout(() => {
                setSuccess(false)
            }, 1000)
        }).catch((err) => {
            setLoading(false)
            if (err.response.status === 400) {
                const listItems = err.response.data.message.map(msg => `<li>${msg}</li>`).join('');
                const errorList = `<ol style="list-style-position: inside; margin-left: 20px;">${listItems}</ol>`; // Added inline styling
                return Swal.fire({
                    title: "Error",
                    html: errorList,
                    icon: "error",
                });
            }
            showWarning("Error occured while saving GRN")
        })
    } catch (error) {
        setLoading(false)
        showWarning("Error occured while saving GRN")
    }
}

export const getGRNno = async ({ setData }) => {
    try {
        axios.get("/grnNumber").then((response) => {
            setData(response.data)
        }).catch(() => {
            showWarning("Something went wrong")
        })
    } catch (error) {
        showWarning("Something went wrong")
    }
}

export const searchCustomer = async (search, page) => {

    return await axios.get('/customers/query', {
        params: { search, page, limit: 10 }
    });
}

export const getInvoicesByCustomer = async ({ customer, setInvoices }) => {
    console.log("Customer API", customer)
    try {

        let response = await axios.get("/invoice/" + customer._id);
        // setInvoices(response.data);
        console
            .log(setInvoices, response)
    } catch (error) {
        console.log(error)
    }
}

export const getAllSuppliers = async ({ setData }) => {
    try {
        await axios.get("/suppliers").then((response) => {
            setData(response.data.data.suppliers)
        }).catch((error) => {
            console.log(error)
            showWarning("Failed to fetch suppliers");
        }
        )
    } catch (error) {
        showWarning("Failed to fetch suppliers");
    }
}

export const getAllProducts = async ({ setData }) => {
    try {
        await axios.get("/products").then((response) => {
            setData(response.data.data)
        }).catch((error) => {
            console.log(error)
            showWarning("Failed to fetch products");
        }
        )
    } catch (error) {
        showWarning("Failed to fetch products");
    }
}

export const deleteQuotationMethod = async ({
    quotationId,
    setIsItemDeleted,
    showSuccess,
}) => {
    try {
        await axios
            .delete("/quotation/" + quotationId)
            .then(() => {
                setIsItemDeleted(true);
                showSuccess("successfully deleted quotation!!");
                setTimeout(() => {
                    setIsItemDeleted(false);
                }, 1000);
            })
            .catch(() => {
                showWarning("Failed to delete the quotation");
            });
    } catch (error) {
        console.log(error);
        showWarning("Failed to delete the quotation");
    }
};

export const getQuotationsByCustomer = async (customer, setQuotaions) => {
    console.log("Customer API", customer);
    try {
        let response = await axios.get(
            "/quotation/" + customer._id
        );
        setQuotaions(response.data);
    } catch (error) {
        console.log(error);
    }
};

export const createIssuanceMethod = async ({ data, setLoading, setSuccess }) => {
    try {
        setLoading(true)
        const userId = JSON.parse(localStorage.getItem('user')).data._id;
        axios.post("/issuance", { ...data, user: userId }).then(() => {
            setLoading(false)
            setSuccess(true)
            setTimeout(() => {
                setSuccess(false)
            }, 1000)
        }).catch((err) => {
            setLoading(false)
            if (err.response.status === 500 || err.response.status === 400) {
                showWarning(err.response.data.message)
                return
            }
            showWarning("Error occured while saving Issuance")
        })
    } catch (error) {
        setLoading(false)
        showWarning("Error occured while saving Issuance")
    }
}

export const getAllIssuancesMethod = async ({ setIssuanceData, setLoading, params, search, startDate, endDate }) => {
    try {
        setLoading(true)
        const userId = JSON.parse(localStorage.getItem('user')).data._id;
        await axios.get("/issuanceses?user="
            + userId + "&page="
            + params.page
            + "&limit="
            + params.limit
            + "&search="
            + search
            + "&startDate="
            + startDate
            + "&endDate="
            + endDate
        ).then((response) => {
            setLoading(false)
            setIssuanceData(response.data)
        }).catch((error) => {
            setLoading(false)
            console.log(error)
            showWarning('Failed to fetch invoices');
        })
    } catch (error) {
        console.log(error)
        showWarning('Failed to fetch invoices');
    }
}

export const deleteIssuanceMethod = async ({ setIsDeleted, id }) => {
    try {
        axios.delete("/issuance?id=" + id).then(() => {
            setIsDeleted(true)
            setTimeout(() => {
                setIsDeleted(false)
            }, 1000)
        }).catch((err) => {
            if (err.response.status === 400) {
                showWarning(err.response.data.message)
                return
            }
            showWarning("Error occured while deleting Issuance")
        })
    } catch (error) {
        showWarning("Error occured while deleting Issuance")
    }
}

export const createDepartmentMethod = async ({ data, setSuccess, setLoading }) => {
    try {
        setLoading(true)
        const userId = JSON.parse(localStorage.getItem('user')).data._id;
        await axios.post(`/departments`, { ...data, user: userId }).then(() => {
            setLoading(false)
            setSuccess(true)
            setTimeout(() => {
                setSuccess(false)
            }, 1000)
        }).catch(() => {
            setLoading(false)
            showWarning("Error occured while saving Department")
        })
    } catch (error) {
        setLoading(false)
        showWarning("Error occured while saving Department")
    }
};

export const getAllDepartments = async ({ search, setData, setLoading }) => {
    try {
        setLoading(true)
        const userId = JSON.parse(localStorage.getItem('user')).data._id;
        await axios.get(`/departments?user=` + userId + "&search=" + search
        ).then((res) => {
            setLoading(false)
            setData(res.data.data)
        }).catch(() => {
            setLoading(false)
            showWarning("Error occured while fetching Department")
        })
    } catch (error) {
        setLoading(false)
        showWarning("Error occured while fetching Department")
    }
};

export const deleteDepartment = async ({ id, setIsDelete }) => {
    try {
        await axios.delete(`/departments/${id}`).then(() => {
            setIsDelete(true)
            setTimeout(() => {
                setIsDelete(false)
            }, 1000)
        }).catch((err) => {
            if (err.response.status === 404) {
                showWarning(err.response.data.message)
                return
            }
            showWarning("Error occured while deleting Department")
        })
    } catch (error) {
        showWarning("Error occured while deleting Department")
    }
};

export const createDesignationMethod = async ({ data, setSuccess, setLoading }) => {
    try {
        setLoading(true)
        const userId = JSON.parse(localStorage.getItem('user')).data._id;
        await axios.post(`/designations`, { ...data, user: userId }).then(() => {
            setLoading(false)
            setSuccess(true)
            setTimeout(() => {
                setSuccess(false)
            }, 1000)
        }).catch(() => {
            setLoading(false)
            showWarning("Error occured while saving Designation")
        })
    } catch (error) {
        setLoading(false)
        showWarning("Error occured while saving Designation")
    }
};

export const getAllDesignations = async ({ search, setData, setLoading }) => {
    try {
        setLoading(true)
        const userId = JSON.parse(localStorage.getItem('user')).data._id;
        await axios.get(`/designations?user=` + userId + "&search=" + search
        ).then((res) => {
            setLoading(false)
            setData(res.data.data.designations)
        }).catch(() => {
            setLoading(false)
            showWarning("Error occured while fetching Designation")
        })
    } catch (error) {
        setLoading(false)
        showWarning("Error occured while fetching Designation")
    }
};

export const deleteDesignation = async ({ id, setIsDelete }) => {
    try {
        await axios.delete(`/designations/${id}`).then(() => {
            setIsDelete(true)
            setTimeout(() => {
                setIsDelete(false)
            }, 1000)
        }).catch((err) => {
            if (err.response.status === 404) {
                showWarning(err.response.data.message)
                return
            }
            showWarning("Error occured while deleting Designation")
        })
    } catch (error) {
        showWarning("Error occured while deleting Designation")
    }
};

export const createEmployeeMethod = async ({ formdata, setLoading, setSuccess }) => {
    //console.log(formdata, setLoading, setSuccess)

    try {
        setLoading(true)
        const userId = JSON.parse(localStorage.getItem('user')).data._id;
        axios.post("/employees?user=" + userId, formdata).then(() => {
            setLoading(false)
            setSuccess(true)
            setTimeout(() => {
                setSuccess(false)
            }, 1000)
        }).catch((err) => {
            setLoading(false)
            if (err.response.status === 400) {
                showWarning(err.response.data.message)
                return
            }
            showWarning("Error occured while saving Employee")
        })
    } catch (error) {
        setLoading(false)
        showWarning("Error occured while saving Employee")
    }
}

export const getAllEmployeesMethod = async ({ setEmployeeData, setLoading, params, search }) => {
    try {
        console.log(params,search)
        setLoading(true)
        await axios.get("/employees?withoutPagination=true"
        ).then((response) => {
            setLoading(false)
            setEmployeeData(response.data)
        }).catch((error) => {
            setLoading(false)
            console.log(error)
            showWarning('Failed to fetch employees');
        })
    } catch (error) {
        console.log(error)
        showWarning('Failed to fetch employees');
    }
}

export const deleteEmployeeMethod = async ({ id, setIsDelete, reason, setLoading }) => {
    try {
        setLoading(true)
        await axios.delete(`/employees/${id}/${reason}`).then(() => {
            setIsDelete(true)
            setTimeout(() => {
                setIsDelete(false)
            }, 1000)
            setLoading(false)
        }).catch((err) => {
            console.log(err)
            setLoading(false)
            if (err.response.status === 404) {
                showWarning(err.response.data.message)
                return
            }
            showWarning("Error occured while deleting Employee")
        })
    } catch (error) {
        setLoading(false)
        console.log(error)
        showWarning("Error occured while deleting Employee")
    }
};

export const getAllEmployeesWithoutPaginationMethod = async ({ setEmployeeData }) => {
    try {
        const userId = JSON.parse(localStorage.getItem('user')).data._id;
        await axios.get("/employees?user=" + userId + "&withoutPagination=" + "yes").then((response) => {
            setEmployeeData([{ value: "", label: "Choose" }, ...response.data.data.map((item) => ({
                value: item._id,
                label: item?.first_name + " " + item?.last_name
            }))])
        }).catch((error) => {
            console.log(error)
            showWarning('Failed to fetch employees');
            alert('Failed to fetch invoices');
        })
    } catch (error) {
        console.log(error)
        alert('Failed to fetch invoices');
        showWarning('Failed to fetch employees');
    }
}

export const getAllShiftsMethod = async ({ setShiftData }) => {
    try {
        //const userId = JSON.parse(localStorage.getItem('user')).data._id;
        await axios.get("/shifts").then((response) => {
            let arrayOfShifts = [
                { value: "", label: "Choose" },
                ...response.data.data.shifts.map((item) => ({
                    value: item._id,
                    label: item?.shiftInfo?.shiftName
                }))
            ]
            setShiftData(arrayOfShifts)

        }).catch((error) => {
            console.log(error)
            showWarning('Failed to fetch shifts');
        })
    } catch (error) {
        console.log(error)
        showWarning('Failed to fetch shifts');
    }
}

export const createAttendanceMethod = async ({ data, setLoading, setSuccess }) => {
    try {
        setLoading(true)
        const userId = JSON.parse(localStorage.getItem('user')).data._id;
        axios.post("/adminAtendances", { ...data, user: userId }).then(() => {
            setLoading(false)
            setSuccess(true)
            setTimeout(() => {
                setSuccess(false)
            }, 1000)
        }).catch((err) => {
            setLoading(false)
            if (err.response.status === 400) {
                showWarning(err.response.data.message)
                return
            }
            showWarning("Error occured while saving Admin Attendance")
        })
    } catch (error) {
        setLoading(false)
        showWarning("Error occured while saving Admin Attendance")
    }
}

export const deleteAdminAttendance = async ({ id, setIsDelete }) => {
    try {
        await axios.delete(`/adminAtendances/${id}`).then(() => {
            setIsDelete(true)
            setTimeout(() => {
                setIsDelete(false)
            }, 1000)
        }).catch((err) => {
            if (err.response.status === 404) {
                showWarning(err.response.data.message)
                return
            }
            showWarning("Error occured while deleting Admin Attendance")
        })
    } catch (error) {
        showWarning("Error occured while deleting Admin Attendance")
    }
};

export const getAllAdmintAttendancesMethod = async ({ setAttendanceData, setLoading, params, search, startDate, endDate, workingHours }) => {
    try {
        setLoading(true)
        const userId = JSON.parse(localStorage.getItem('user')).data._id;
        await axios.get("/adminAtendances?user="
            + userId + "&page="
            + params.page
            + "&limit="
            + params.limit
            + "&search="
            + search
            + "&dateFrom="
            + startDate
            + "&dateTo="
            + endDate
            + "&workingHours="
            + workingHours
        ).then((response) => {
            setLoading(false)
            setAttendanceData(response.data)
        }).catch((error) => {
            setLoading(false)
            console.log(error)
            showWarning('Failed to fetch attendance');
        })
    } catch (error) {
        console.log(error)
        showWarning('Failed to fetch attendance');
    }
}

export const getAllAllowances = async ({ search, setLoading }) => {
    try {
        const response = await axios.get("/allowances?user=" + JSON.parse(localStorage.getItem('user')).data._id + "&search=" + search);
        setLoading(false)
        return response.data
    } catch (error) {
        setLoading(false)
        console.log(error)
        showWarning('Failed to fetch allowance');
    }
}

export const createAllwanceMethod = async ({ name, handleCancel, handleReset, showSuccess, handleAllowanceAdding, setLoading, id }) => {
    try {
        const userId = JSON.parse(localStorage.getItem('user')).data._id;
        setLoading(true)
        await axios.post("/allowances", { id, name, user: userId })
            .then((response) => {
                handleCancel();
                handleReset();
                setLoading(false)
                showSuccess()
                handleAllowanceAdding()
                console.log(response)
            }).catch((error) => {
                setLoading(false)
                console.log(error)
                if (error.response.status === 400) {
                    showWarning(error.response.data.error)
                    return
                }
                showWarning("Failed to create allowance");
            })
    } catch (error) {
        console.log(error)
        showWarning("Failed to create allowance");
    }
}

export const deleteAllowanceMethod = async ({ id, setIsDelete, setLoading }) => {
    try {
        setLoading(true)
        await axios.delete(`/allowances?id=${id}`).then(() => {
            setIsDelete(true)
            setTimeout(() => {
                setIsDelete(false)
            }, 1000)
            setLoading(false)
        }).catch((err) => {
            console.log(err)
            setLoading(false)
            if (err.response.status === 404) {
                showWarning(err.response.data.message)
                return
            }
            showWarning("Error occured while deleting Employee")
        })
    } catch (error) {
        setLoading(false)
        console.log(error)
        showWarning("Error occured while deleting Employee")
    }
};

export const getAllInventories = async ({ setInventoryData, setLoading, params }) => {
    try {
        setLoading(true)
        await axios.get("/inventories",{
            params:{...params}
        }
        ).then((response) => {
           console.log(response.data)
            setLoading(false)
           setInventoryData(response.data)
        }).catch((error) => {
            setLoading(false)
            console.log(error)
            showWarning('Failed to fetch inventory');
        })
    } catch (error) {
        console.log(error)
        showWarning('Failed to fetch inventory');
    }
}

