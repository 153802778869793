import React, {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import {Filter, PlusCircle} from 'feather-icons-react/build/IconComponents';
import Select from 'react-select';
import {DatePicker, Descriptions, Modal, Popconfirm, Table, Tooltip as ToolPro,Tag} from 'antd';
import AddJob from '../../core/modals/inventory/addjob';

import {deleteJobMethod, getAllJobMethod, jobStatusChangeMethod} from '../../service/ApiServices';
import Swal from 'sweetalert2';
import {useRef} from 'react';
import {jsPDF} from "jspdf";
import "jspdf-autotable";

const {RangePicker} = DatePicker;
import dayjs from 'dayjs';
import axios from "axios";

const JobList = () => {
    const startOfCurrentMonth = dayjs().startOf('month');
    const endOfCurrentMonth = dayjs().endOf('month');

    const [selectedRange, setSelectedRange] = useState([
        startOfCurrentMonth,
        endOfCurrentMonth
    ]);

    const [isJobAdded, setIsJobAdded] = useState(false);
    const [selectedStatus, setSelectedStatus] = useState('');
    const [isStatusChanged, setIsStatusChanged] = useState(false);
    const [selectedElement, setSelectedElement] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isModalOpen2, setIsModalOpen2] = useState(false);
    const [isModalOpen3, setIsModalOpen3] = useState(false);
    const [selectedDate, setSelectedDate] = useState(null);
    const [isFilterVisible] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');
    const [isItemDeleted, setIsItemDeleted] = useState(false)
    const [selectedJob, setSelectedJob] = useState("")
    const [loading, setLoading] = useState(false)
    const [jobData, setJobData] = useState({
        data: [],
        page: 1,
        limit: 10,
        total: 0
    });
    const addJobRef = useRef();

    const callHandleReset = () => {
        if (addJobRef.current) {
            addJobRef.current.handleReset();
        }
    };

    useEffect(() => {
        if (isJobAdded || isStatusChanged || isItemDeleted) {
            const fetchJobData = async () => {
                try {
                    const response = await getAllJobMethod({
                        search: searchQuery,
                        params: {page: jobData.page, limit: jobData.limit},
                        setLoading,
                        startDate: selectedRange[0].format("YYYY-MM-DD"),
                        endDate: selectedRange[1].format("YYYY-MM-DD")
                    })
                    setJobData(response);
                } catch (error) {
                    console.error('Error fetching job data:', error);
                }
            };
            fetchJobData();
        }
    }, [isJobAdded, isStatusChanged, isItemDeleted])

    useEffect(() => {
        const handler = setTimeout(async () => {
            const response = await getAllJobMethod({
                search: searchQuery,
                setLoading,
                params: {
                    page: jobData.page, limit: jobData.limit,
                    startDate: selectedRange[0].format("YYYY-MM-DD"),
                    endDate: selectedRange[1].format("YYYY-MM-DD")
                },
                startDate: selectedRange[0].format("YYYY-MM-DD"),
                endDate: selectedRange[1].format("YYYY-MM-DD")
            })
            setJobData(response);
        }, 500);

        return () => {
            clearTimeout(handler);
        };
    }, [searchQuery, selectedRange]);

    const handleJobAdded = () => {
        setIsJobAdded(true);
        setTimeout(() => {
            setIsJobAdded(false);
        }, 3000);
    }

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleOk = () => {
        setIsModalOpen(false);
    };

    const handleCancel = () => {
        //setSelectedJob("")
        setIsModalOpen(false);
    };

    const showModal2 = () => {
        setIsModalOpen2(true);
    };

    const handleOk2 = () => {
        setIsModalOpen2(false);
    };

    const handleCancel2 = () => {
        setIsModalOpen2(false);
    };
    const showModal3 = async (record) => {
        console.log("Record", record)
        const response = await axios.get("/get-job-no/" + record.jobNo);
        let job = response.data;
        setSelectedJob(job)
        setIsModalOpen3(true);
    };

    const handleOk3 = () => {
        setIsModalOpen3(false);
    };

    const handleDateChange = (date) => {
        setSelectedDate(date);
    };


    const oldandlatestvalue = [
        {value: 'date', label: 'Sort by Date'},
        {value: 'newest', label: 'Newest'},
        {value: 'oldest', label: 'Oldest'},
    ];
    const statusOptions = [
        {value: 'choose Status', label: 'Choose Status'},
        {value: 'Active', label: 'Active'},
        {value: 'Inactive', label: 'Inactive'},
    ];
    const columns = [
        {
            title: "Job No",
            dataIndex: "jobNo",
            sorter: (a, b) => a.jobNo.length - b.jobNo.length,
        },
        {
            title: "Invoice No",
            dataIndex: "jobNo",
            sorter: (a, b) => a.jobNo.length - b.jobNo.length,
        },
        {
            title: "Customer",
            dataIndex: ["customer", "customerName"],
            sorter: (a, b) => a.customer.length - b.customer.length,
        },
        {
            title: "Job Date",
            dataIndex: "jobDate",
            render: (text, record) => {
                return record.jobDate ? new Date(record.jobDate).toLocaleDateString() :
                    <span className="badge bg-danger">N/A</span>
            },
            sorter: (a, b) => new Date(a.deliveryDate) - new Date(b.deliveryDate),
        },
        {
            title: "FitOn Date",
            dataIndex: "fitOnDate",
            render: (text, record) => {
                return record.fitOnDate ? new Date(record.fitOnDate).toLocaleDateString() :
                    <span className="badge bg-danger">N/A</span>
            },
            sorter: (a, b) => new Date(a.deliveryDate) - new Date(b.deliveryDate),
        },
        {
            title: "Delivery Date",
            dataIndex: "deliveryDate",
            render: (text, record) => {
                return record.deliveryDate ? new Date(record.deliveryDate).toLocaleDateString() :
                    <span className="badge bg-danger">N/A</span>
            },
            sorter: (a, b) => new Date(a.deliveryDate) - new Date(b.deliveryDate),
        },
        {
            title: "Status",
            dataIndex: "status",
            render: (text, record) => (

                <div style={{
                    display: 'flex',
                    alignContent: 'start',
                    justifyContent: "space-between",
                    width: "120px"
                }}>
                    <div
                        className={text === 'pending' ? "badge bg-warning" : text === 'in-progress' ? "badge bg-info" : text === 'completed' ? "badge bg-success" : text === 'cancelled' ? "badge bg-danger" : ""}>
                        {text}
                    </div>
                    <ToolPro title="Edit Status">
                        <div className="add-newplus">
                            <Link onClick={() => {
                                showModal2()
                                setSelectedElement(record)
                                setSelectedStatus(record.status)
                            }}>
                                <PlusCircle className="plus-down-add"/>
                            </Link>
                        </div>
                    </ToolPro>
                </div>
            )
        },
        {
            title: 'Actions',
            dataIndex: 'actions',
            key: 'actions',
            render: (text, record) => (
                <td className="action-table-data">
                    <div className="edit-delete-action">
                        <Link className="me-2 p-2" to="#"
                              hidden
                              onClick={() => {
                                  setSelectedJob(record)
                                  showModal()
                              }}
                        >
                            <i data-feather="edit" className="feather-edit"></i>
                        </Link>
                        <Link className="me-2 p-2" to="#"
                              onClick={() => {

                                  showModal3(record)
                              }}
                        >
                            <i data-feather="show" className="feather-eye"></i>
                        </Link>
                        <Link className="me-2 p-2" to="#"
                              onClick={() => {
                                  generatePDF(record)
                              }}
                        >
                            <i data-feather="download" className="feather-download"></i>
                        </Link>
                        <Popconfirm
                            title="Delete the job"
                            description="Are you sure to delete this job?"
                            onConfirm={() => {

                                deleteJobMethod({
                                    jobId: record._id,
                                    setIsItemDeleted,
                                    showSuccessDeleted
                                })
                            }}
                            onCancel={() => {
                                console.log("c")
                            }}
                            okText="Yes"
                            cancelText="No"
                        >
                            <Link className="confirm-text p-2" to="#">
                                <i data-feather="trash-2"
                                   className="feather-trash-2"

                                ></i>
                            </Link>
                        </Popconfirm>
                    </div>
                </td>
            )
        },
    ];
    const columnsforview = [
        {
            title: 'Job Type',
            dataIndex: 'jobType',
            key: 'jobType',
            render: jobType => jobType.type,
        },
        {
            title: 'Quantity',
            dataIndex: 'qty',
            key: 'qty',
        },
        {
            title: 'Measurements',
            dataIndex: 'measurements',
            key: 'measurements',
        },
        {
            title: "Body Type",
            dataIndex: "bodyTypes",
            key: "bodyTypes",
            render: bodyTypes => (
                <ul>
                    {bodyTypes?.map(body => (
                        <li key={body.id}>
                            {body.name}:
                            {body.values.map(value => (
                                <Tag key={value} color="blue" style={{ marginLeft: '5px' }}>
                                    {value}
                                </Tag>
                            ))}
                        </li>
                    ))}
                </ul>
            ),
        },
        {
            title: 'Styles',
            dataIndex: 'styles',
            key: 'styles',
            render: styles => (
                <ul>
                    {styles.map(style => (
                        <li key={style._id}>
                            {style.style.styleName}:
                            {style.selectedValues.map(value => (
                                <Tag key={value} color="green" style={{ marginLeft: '5px' }}>
                                    {value}
                                </Tag>
                            ))}
                        </li>
                    ))}
                </ul>
            ),
        },
    ];
    const showSuccessChangeStatus = () => {
        return Swal.fire({
            title: "Good job!",
            text: "You Successfully Change Status!",
            icon: "success"
        });
    }
    const showSuccessDeleted = () => {
        return Swal.fire({
            title: "Good job!",
            text: "You Successfully Cancelled Job!",
            icon: "success"
        });
    }

    const generatePDF = async (jobObj) => {
        const response = await axios.get("/get-job-no/" + jobObj.jobNo);
        let job = response.data;
        const doc = new jsPDF({
            orientation: "portrait",
            unit: "pt",
            format: [595.28, 350], // Half A4 size
        });

        const pageWidth = doc.internal.pageSize.getWidth();
        const pageHeight = doc.internal.pageSize.getHeight();

        // Constants for two-column layout
        const columnWidth = (pageWidth - 40) / 2; // Two columns with margins
        const margin = 20;
        const rowHeight = 15;

        let currentY = margin;

        // Header Section
        doc.setFontSize(14);
        doc.setFont("helvetica", "bold");
        doc.text("Job Card", pageWidth / 2, currentY, {align: "center"});
        currentY += 15;

        // Horizontal Divider
        doc.setLineWidth(0.5);
        doc.line(margin, currentY, pageWidth - margin, currentY);
        currentY += 15;

        // Helper function to render two columns per row
        const renderTwoColumns = (fields) => {
            let columnIndex = 0;
            let rowY = currentY;

            fields.forEach((field, index) => {
                const columnX = margin + columnIndex * columnWidth;

                // Print field
                doc.setFontSize(10);
                doc.setFont("helvetica", "normal");
                doc.text(`${field.label}: ${field.value}`, columnX, rowY);

                columnIndex++;
                if (columnIndex === 2 || index === fields.length - 1) {
                    columnIndex = 0;
                    rowY += rowHeight;
                }
            });

            return rowY;
        };

        // Job Details (General Information)
        const jobDetails = [
            {label: "Job No", value: job.jobNo || "N/A"},
            {label: "Job Date", value: new Date(job.jobDate).toLocaleDateString() || "N/A"},
            {label: "Fit On Date", value: new Date(job.fitOnDate).toLocaleDateString() || "N/A"},
            {label: "Final Fit On Date", value: new Date(job.finalFitOnDate).toLocaleDateString() || "N/A"},
            {label: "Delivery Date", value: new Date(job.deliveryDate).toLocaleDateString() || "N/A"},
            {label: "Tailor", value: job.tailor?.name || "N/A"},
            {label: "Cutter", value: job.cutter?.name || "N/A"},
            {label: "Remarks", value: job.remarks || "N/A"},
        ];

        currentY = renderTwoColumns(jobDetails);

        // Add Section for Job Types
        currentY += 15; // Space before next section
        doc.setFontSize(12);
        doc.setFont("helvetica", "bold");
        doc.text("Job Type Details", margin, currentY);
        currentY += 10;

        // Horizontal Divider
        doc.setLineWidth(0.5);
        doc.line(margin, currentY, pageWidth - margin, currentY);
        currentY += 10;
        job.jobTypesInfo.forEach((jobTypeInfo) => {

            const jobTypeDetails = [
                {label: "Job Type", value: jobTypeInfo.jobType?.type || "N/A"},
                {label: "Quantity", value: jobTypeInfo.qty || "N/A"},
                {label: "Measurements", value: jobTypeInfo.measurements || "N/A"},
            ];

            currentY = renderTwoColumns(jobTypeDetails);
            currentY += 10;

            // Styles
            jobTypeInfo.styles.forEach((style) => {
                doc.text(
                    `${style.style?.styleName || "N/A"} :(${style.selectedValues.join(", ")})`,
                    margin,
                    currentY
                );
                currentY += rowHeight;
            });
            currentY += 10;
            // Body Types
            jobTypeInfo.bodyTypes.forEach((bodyType) => {
                doc.text(
                    `${bodyType.name || "N/A"}: (${bodyType.values.join(", ")})`,
                    margin,
                    currentY
                );
                currentY += rowHeight;
            });

            currentY += 15; // Space between job types
            if (currentY > pageHeight - margin) {
                doc.addPage();
                currentY = margin;
            }
        });

        // Save PDF
        doc.save(`two-column-job-card-${job.jobNo}.pdf`);
    };

    const handleTableChange = async (pagination) => {
        const response = await getAllJobMethod({
            search: searchQuery,
            params: {page: pagination.current, limit: pagination.pageSize},
            setLoading,
            startDate: selectedRange[0].format("YYYY-MM-DD"),
            endDate: selectedRange[1].format("YYYY-MM-DD")
        })
        setJobData(response);
    };
    const onChange = (dates) => {
        if (dates === null) {
            setSelectedRange([startOfCurrentMonth, endOfCurrentMonth])
            return
        }
        setSelectedRange(dates);
    };
    return (
        <div className="page-wrapper">
            <div className="content">
                <div className="page-header">
                    <div className="add-item d-flex">
                        <div className="page-title">
                            <h4>Jobs</h4>
                            <h6>Manage your jobs</h6>
                        </div>
                    </div>

                    <div className="page-btn">
                        <a

                            className="btn btn-added"
                            onClick={() => {
                                showModal()
                                callHandleReset()
                            }}
                        >
                            <PlusCircle className="me-2"/>
                            Create Job
                        </a>
                    </div>
                </div>
                {/* /product list */}
                <div className="card table-list-card">
                    <div className="card-body">
                        <div className="table-top">
                            <div className="search-set">
                                <div className="search-input">
                                    <input
                                        type="search"
                                        className="form-control"
                                        value={searchQuery}
                                        onChange={(e) => setSearchQuery(e.target.value)}
                                    />
                                    <Link to="#" className="btn btn-searchset">
                                        <i data-feather="search"
                                           className="feather-search"
                                        />
                                    </Link>
                                </div>
                                <RangePicker onChange={onChange} value={selectedRange} format="YYYY-MM-DD"/>

                            </div>

                        </div>
                        {isFilterVisible && (
                            <div className="card-body pb-0">
                                <div className="row">
                                    <div className="col-lg-3 col-sm-6 col-12">
                                        <div className="form-group">
                                            <DatePicker
                                                selected={selectedDate}
                                                onChange={handleDateChange}
                                                placeholderText="Choose Date"
                                            />
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-sm-6 col-12">
                                        <div className="form-group">
                                            <Select options={statusOptions}/>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-sm-6 col-12">
                                        <div className="form-group">
                                            <Select options={oldandlatestvalue}/>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-sm-6 col-12">
                                        <div className="form-group">
                                            <a className="btn btn-filters ms-auto">
                                                <Filter className="me-2"/> Filter
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                        <Table
                            columns={columns}
                            dataSource={jobData?.data}
                            rowKey="_id"
                            pagination={{
                                current: jobData?.page,
                                pageSize: jobData?.limit,
                                total: jobData?.total
                            }}
                            loading={loading}
                            onChange={handleTableChange}
                        />
                    </div>
                </div>
            </div>
            <Modal
                title="Change Status"
                width={"25vw"}
                zIndex={100000}
                open={isModalOpen2}
                onOk={handleOk2}
                onCancel={handleCancel2}
                maskClosable={false}
                footer={null}
            >
                <div className="col-lg-12 col-sm-12 col-12">
                    <div className="input-blocks">
                        <select
                            className="form-select"
                            value={selectedStatus}
                            onChange={(e) => setSelectedStatus(e.target.value)}
                        >
                            <option value={"pending"}>Pending</option>
                            <option value={"in-progress"}>In Progress</option>
                            <option value={"completed"}>Completed</option>
                            <option value={"delivered"}>Delivered</option>
                        </select>
                    </div>
                </div>
                <div className="modal-footer-btn d-flex justify-content-start">
                    <button
                        type="button"
                        className="btn btn-cancel me-2"
                        onClick={handleCancel2}
                    >
                        Cancel
                    </button>
                    <button
                        type="button"
                        className="btn btn-save"
                        onClick={() => {
                            jobStatusChangeMethod({
                                jobId: selectedElement._id,
                                jobStatus: selectedStatus,
                                setIsStatusChanged,
                                handleCancel: handleCancel2,
                                showSuccessChangeStatus
                            })
                        }}
                    >
                        Save
                    </button>
                </div>
            </Modal>
            <Modal
                title=""
                width={"75vw"}
                zIndex={100000}
                open={isModalOpen}
                onOk={handleOk}
                onCancel={() => {
                    //setSelectedJob("")
                    setIsModalOpen(false)
                }}
                closable={false}
                maskClosable={false}
                footer={null}
            >
                <AddJob
                    ref={addJobRef}
                    handleCancel2={handleCancel}
                    handleJobAdded={handleJobAdded}
                    selectedJob={selectedJob}
                />
            </Modal>
            <Modal
                title={`Job Details - ${selectedJob.jobNo}`}
                width="75vw"
                zIndex={100000}

                open={isModalOpen3}
                onOk={handleOk3}
                onCancel={() => {
                    setSelectedJob("")
                    setIsModalOpen3(false)
                }}
                maskClosable={false}
                footer={null}
            >
                <Descriptions title="Customer Info" bordered layout="vertical" column={2}
                              style={{marginBottom: '20px'}}>
                    <Descriptions.Item label="Name">{selectedJob?.customer?.customerName}</Descriptions.Item>
                    <Descriptions.Item label="Email">{selectedJob?.customer?.customerEmail}</Descriptions.Item>
                    <Descriptions.Item label="Phone">{selectedJob?.customer?.customerPhone}</Descriptions.Item>
                    <Descriptions.Item label="Address">{selectedJob?.customer?.customerAddress}</Descriptions.Item>
                    <Descriptions.Item label="City">{selectedJob?.customer?.customerCity}</Descriptions.Item>
                    <Descriptions.Item label="Country">{selectedJob?.customer?.customerCountry}</Descriptions.Item>
                    <Descriptions.Item label="Description"
                                       span={2}>{selectedJob?.customer?.customerDescription}</Descriptions.Item>
                </Descriptions>

                <Descriptions title="Job Info" bordered layout="vertical" column={2} style={{marginBottom: '20px'}}>
                    <Descriptions.Item label="Job No">{selectedJob?.jobNo}</Descriptions.Item>
                    <Descriptions.Item
                        label="Job Date">{selectedJob?.jobDate ? new Date(selectedJob?.jobDate).toLocaleDateString() : "----"}</Descriptions.Item>
                    <Descriptions.Item
                        label="Fit On Date">{selectedJob?.fitOnDate ? new Date(selectedJob?.fitOnDate).toLocaleDateString() : "----"}</Descriptions.Item>
                    <Descriptions.Item
                        label="Delivery Date">{selectedJob?.deliveryDate ? new Date(selectedJob?.deliveryDate).toLocaleDateString() : "----"}</Descriptions.Item>
                    <Descriptions.Item
                        label="Final Fit On Date">{selectedJob?.finalFitOnDate ? new Date(selectedJob?.finalFitOnDate).toLocaleDateString() : "----"}</Descriptions.Item>
                    <Descriptions.Item
                        label="Tailor">{selectedJob?.tailor?.first_name + " " + selectedJob?.tailor?.last_name}</Descriptions.Item>
                    <Descriptions.Item
                        label="Cutter">{selectedJob?.cutter?.first_name + " " + selectedJob?.cutter?.last_name}</Descriptions.Item>
                    <Descriptions.Item label="Status" span={2}>{selectedJob?.status}</Descriptions.Item>
                </Descriptions>
                <Descriptions title="Job Type Info"> </Descriptions>
                <Table
                    title={() => ''}
                    columns={columnsforview}
                    dataSource={selectedJob?.jobTypesInfo}
                    rowKey={record => record._id}
                    style={{marginTop: '20px'}}
                    pagination={false}
                    bordered
                />

            </Modal>
        </div>
    );
};

export default JobList;

