import { useParams } from "react-router-dom";
import React, { useState, useEffect } from "react";
import { API_BASE_URL } from "../../../src/environment";
import { PlusCircle } from 'react-feather';
import { Link } from "react-router-dom";

const AddUserRight = () => {
  const { uid } = useParams();
  const [userName, setUserName] = useState("");
  const [userRights, setUserRights] = useState([]);

  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        const response = await fetch(`${API_BASE_URL}/user/getbyuid/${uid}`);
        const data = await response.json();

        if (response.ok) {
          const fetchedUserName = data.name || "";
          setUserName(fetchedUserName);
          setUserRights([
             { userid: uid, user: fetchedUserName, right: "Dashboard", status: 0 },
             
             { userid: uid, user: fetchedUserName, right: "Jobs", status: 0, adds: 0, viewes: 0, updates: 0, deletes: 0, download: 0, upload: 0 ,approve:0},
             { userid: uid, user: fetchedUserName, right: "Services", status: 0, adds: 0, viewes: 0, updates: 0, deletes: 0, download: 0, upload: 0,approve:0 },
             { userid: uid, user: fetchedUserName, right: "Body-Types", status: 0, adds: 0, viewes: 0, updates: 0, deletes: 0, download: 0, upload: 0 ,approve:0},
             
             { userid: uid, user: fetchedUserName, right: "Inventory", status: 0, adds: 0, viewes: 0, updates: 0, deletes: 0, download: 0, upload: 0,approve:0 },
             { userid: uid, user: fetchedUserName, right: "product-list", status: 0, adds: 0, viewes: 0, updates: 0, deletes: 0, download: 0, upload: 0 ,approve:0},
             { userid: uid, user: fetchedUserName, right: "add-product", status: 0, adds: 0, viewes: 0, updates: 0, deletes: 0, download: 0, upload: 0 ,approve:0},
             { userid: uid, user: fetchedUserName, right: "services", status: 0, adds: 0, viewes: 0, updates: 0, deletes: 0, download: 0, upload: 0 ,approve:0},
             { userid: uid, user: fetchedUserName, right: "grn", status: 0, adds: 0, viewes: 0, updates: 0, deletes: 0, download: 0, upload: 0 ,approve:0},
             { userid: uid, user: fetchedUserName, right: "issuance", status: 0, adds: 0, viewes: 0, updates: 0, deletes: 0, download: 0, upload: 0,approve:0 },
             { userid: uid, user: fetchedUserName, right: "low-stocks", status: 0, adds: 0, viewes: 0, updates: 0, deletes: 0, download: 0, upload: 0,approve:0 },
             { userid: uid, user: fetchedUserName, right: "category-list", status: 0, adds: 0, viewes: 0, updates: 0, deletes: 0, download: 0, upload: 0 ,approve:0},
             { userid: uid, user: fetchedUserName, right: "sub-categories", status: 0, adds: 0, viewes: 0, updates: 0, deletes: 0, download: 0, upload: 0 ,approve:0},
             { userid: uid, user: fetchedUserName, right: "brand-list", status: 0, adds: 0, viewes: 0, updates: 0, deletes: 0, download: 0, upload: 0 ,approve:0},
             { userid: uid, user: fetchedUserName, right: "units", status: 0, adds: 0, viewes: 0, updates: 0, deletes: 0, download: 0, upload: 0,approve:0 },
           
             { userid: uid, user: fetchedUserName, right: "Manage-Stock", status: 0, adds: 0, viewes: 0, updates: 0, deletes: 0, download: 0, upload: 0 ,approve:0},
             { userid: uid, user: fetchedUserName, right: "Stock-Adjustment", status: 0, adds: 0, viewes: 0, updates: 0, deletes: 0, download: 0, upload: 0,approve:0 },
             { userid: uid, user: fetchedUserName, right: "Stock-Transfer", status: 0, adds: 0, viewes: 0, updates: 0, deletes: 0, download: 0, upload: 0,approve:0 },
            
             { userid: uid, user: fetchedUserName, right: "Invoices", status: 0, adds: 0, viewes: 0, updates: 0, deletes: 0, download: 0, upload: 0 ,approve:0},
             { userid: uid, user: fetchedUserName, right: "Quotation", status: 0, adds: 0, viewes: 0, updates: 0, deletes: 0, download: 0, upload: 0,approve:0 },
             
             { userid: uid, user: fetchedUserName, right: "Coupons", status: 0, adds: 0, viewes: 0, updates: 0, deletes: 0, download: 0, upload: 0,approve:0 },
           
             { userid: uid, user: fetchedUserName, right: "Purchases", status: 0, adds: 0, viewes: 0, updates: 0, deletes: 0, download: 0, upload: 0,approve:0 },
             { userid: uid, user: fetchedUserName, right: "Purchase-Order", status: 0, adds: 0, viewes: 0, updates: 0, deletes: 0, download: 0, upload: 0,approve:0 },
             { userid: uid, user: fetchedUserName, right: "Purchase-Return", status: 0, adds: 0, viewes: 0, updates: 0, deletes: 0, download: 0, upload: 0,approve:0 },
             { userid: uid, user: fetchedUserName, right: "expense-list", status: 0, adds: 0, viewes: 0, updates: 0, deletes: 0, download: 0, upload: 0,approve:0 },
             { userid: uid, user: fetchedUserName, right: "expense-category", status: 0, adds: 0, viewes: 0, updates: 0, deletes: 0, download: 0, upload: 0,approve:0 },
             //{ userid: uid, user: fetchedUserName, right: "Purchase-Return", status: 0, adds: 0, viewes: 0, updates: 0, deletes: 0, download: 0, upload: 0 },
             { userid: uid, user: fetchedUserName, right: "Customers", status: 0, adds: 0, viewes: 0, updates: 0, deletes: 0, download: 0, upload: 0,approve:0 },
             { userid: uid, user: fetchedUserName, right: "Suppliers", status: 0, adds: 0, viewes: 0, updates: 0, deletes: 0, download: 0, upload: 0 ,approve:0},
             { userid: uid, user: fetchedUserName, right: "Employees", status: 0, adds: 0, viewes: 0, updates: 0, deletes: 0, download: 0, upload: 0 ,approve:0},
             { userid: uid, user: fetchedUserName, right: "Departments", status: 0, adds: 0, viewes: 0, updates: 0, deletes: 0, download: 0, upload: 0 ,approve:0},
             { userid: uid, user: fetchedUserName, right: "Designations", status: 0, adds: 0, viewes: 0, updates: 0, deletes: 0, download: 0, upload: 0 ,approve:0},
             { userid: uid, user: fetchedUserName, right: "Shifts", status: 0, adds: 0, viewes: 0, updates: 0, deletes: 0, download: 0, upload: 0 ,approve:0},
             { userid: uid, user: fetchedUserName, right: "Attendance", status: 0, adds: 0, viewes: 0, updates: 0, deletes: 0, download: 0, upload: 0,approve:0 },
             { userid: uid, user: fetchedUserName, right: "attendance-employee", status: 0, adds: 0, viewes: 0, updates: 0, deletes: 0, download: 0, upload: 0,approve:0 },
             { userid: uid, user: fetchedUserName, right: "attendance-admin", status: 0, adds: 0, viewes: 0, updates: 0, deletes: 0, download: 0, upload: 0,approve:0 },
             { userid: uid, user: fetchedUserName, right: "Leaves", status: 0, adds: 0, viewes: 0, updates: 0, deletes: 0, download: 0, upload: 0,approve:0 },
             { userid: uid, user: fetchedUserName, right: "leaves-employee", status: 0, adds: 0, viewes: 0, updates: 0, deletes: 0, download: 0, upload: 0 ,approve:0},
             { userid: uid, user: fetchedUserName, right: "leaves-admin", status: 0, adds: 0, viewes: 0, updates: 0, deletes: 0, download: 0, upload: 0,approve:0 },
             { userid: uid, user: fetchedUserName, right: "leave-types", status: 0, adds: 0, viewes: 0, updates: 0, deletes: 0, download: 0, upload: 0 ,approve:0},
             { userid: uid, user: fetchedUserName, right: "Holidays", status: 0, adds: 0, viewes: 0, updates: 0, deletes: 0, download: 0, upload: 0,approve:0 },
             { userid: uid, user: fetchedUserName, right: "Payroll", status: 0, adds: 0, viewes: 0, updates: 0, deletes: 0, download: 0, upload: 0 ,approve:0},
             { userid: uid, user: fetchedUserName, right: "Purchase-Report", status: 0, adds: 0, viewes: 0, updates: 0, deletes: 0, download: 0, upload: 0 ,approve:0},
             { userid: uid, user: fetchedUserName, right: "Inventory-Report", status: 0, adds: 0, viewes: 0, updates: 0, deletes: 0, download: 0, upload: 0 ,approve:0},
             { userid: uid, user: fetchedUserName, right: "Invoice-Report", status: 0, adds: 0, viewes: 0, updates: 0, deletes: 0, download: 0, upload: 0,approve:0 },
             { userid: uid, user: fetchedUserName, right: "Profit-Loss", status: 0, adds: 0, viewes: 0, updates: 0, deletes: 0, download: 0, upload: 0 ,approve:0},
             { userid: uid, user: fetchedUserName, right: "Users", status: 0, adds: 0, viewes: 0, updates: 0, deletes: 0, download: 0, upload: 0,approve:0},
             { userid: uid, user: fetchedUserName, right: "Roles-Permissions", status: 0, adds: 0, viewes: 0, updates: 0, deletes: 0, download: 0, upload: 0 ,approve:0},
             { userid: uid, user: fetchedUserName, right: "user-Rights", status: 0, adds: 0, viewes: 0, updates: 0, deletes: 0, download: 0, upload: 0 ,approve:0},
             { userid: uid, user: fetchedUserName, right: "General-Setting", status: 0, adds: 0, viewes: 0, updates: 0, deletes: 0, download: 0, upload: 0,approve:0 },
            { userid: uid, user: fetchedUserName, right: "Login-Out", status: 1, adds: 0, viewes: 0, updates: 0, deletes: 0, download: 0, upload: 0,approve:0},
            // Add other rights as needed
          ]);
        } else {
          console.error(data.error || "Failed to fetch user details");
        }
      } catch (error) {
        console.error("Error fetching user details: ", error);
      }
    };

    fetchUserDetails();
  }, [uid]);

  const handleCheckboxChange = (index, type) => {
    setUserRights((prevRights) =>
      prevRights.map((right, i) =>
        i === index
          ? { ...right, [type]: right[type] === 1 ? 0 : 1 }
          : right
      )
    );
  };

  const handleStatusChange = (index) => {
    setUserRights((prevRights) =>
      prevRights.map((right, i) =>
        i === index
          ? { ...right, status: right.status === 1 ? 0 : 1 }
          : right
      )
    );
  };

  const handleSave = async () => {
    try {
      for (const right of userRights) {
        const userRightToSave = {
          userid: right.userid,
          user: right.user,
          role_id:1,
          right: right.right,
          status: right.status,
          adds: right.adds,
          viewes: right.viewes,
          updates: right.updates,
          deletes: right.deletes,
          download: right.download,
          upload: right.upload,
          approve:right.approve,
        };

        const response = await fetch(`${API_BASE_URL}/userright/create`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(userRightToSave),
        });

        const data = await response.json();

        if (!response.ok) {
          console.error("Error response data:", data);
          alert("Error saving user rights: " + data.message);
          return; // Exit if there's an error
        }
      }
      alert("User rights saved successfully!");
    } catch (error) {
      console.error("Error saving data: ", error);
      alert("An error occurred while saving user rights.");
    }
  };

  return (
    <div className="page-wrapper">
      <div className="content">
        <div className="page-header">
          <div className="page-title">
            <h4>Add New User Access Rights For User Name: {userName}</h4>
            <h6>The level of permissions granted to a user account within a system</h6>
          </div>
          <Link to="/userrights" className="btn btn-added">
            <PlusCircle className="me-2" />
            Back to User Rights
          </Link>
        </div>
        <div className="card">
          <div className="card-body">
            <div className="table-responsive">
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th>User Access Rights Name</th>
                    <th>Status</th>
                    <th>Adds</th>
                    <th>Viewes</th>
                    <th>Updates</th>
                    <th>Deletes</th>
                    <th>Download</th>
                    <th>Upload</th>
                    <th>Approve</th>
                  </tr>
                </thead>
                <tbody>
                  {userRights.map((right, index) => (
                    <tr key={index}>
                      <td>{right.right}</td>
                      <td>
                        <input
                          type="checkbox"
                          id={`status-checkbox-${index}`}
                          checked={right.status === 1}
                          onChange={() => handleStatusChange(index)}
                          style={{ cursor: "pointer" }}
                        />
                        <label
                          htmlFor={`status-checkbox-${index}`}
                          style={{
                            display: "inline-block",
                            marginLeft: "8px",
                            color: right.status === 1 ? "green" : "red",
                          }}
                        >
                          {right.status === 1 ? "Granted" : "Not Granted"}
                        </label>
                      </td>
                      <td>
                        
                      
                    <input
                      type="checkbox"
                      id={`adds-checkbox-${index}`}
                      checked={right.adds === 1}
                      onChange={() => handleCheckboxChange(index, 'adds')}
                      style={{ cursor: "pointer" }}
                    />
               
                      </td>
                      <td>

                        <input
                          type="checkbox"
                          id={`viewes-checkbox-${index}`}
                          checked={right.viewes === 1}
                          onChange={() => handleCheckboxChange(index, 'viewes')}
                          style={{ cursor: "pointer" }}
                        />
                      </td>
                      <td>
                        <input
                          type="checkbox"
                          id={`updates-checkbox-${index}`}
                          checked={right.updates === 1}
                          onChange={() => handleCheckboxChange(index, 'updates')}
                          style={{ cursor: "pointer" }}
                        />
                      </td>
                      <td>
                        <input
                          type="checkbox"
                          id={`deletes-checkbox-${index}`}
                          checked={right.deletes === 1}
                          onChange={() => handleCheckboxChange(index, 'deletes')}
                          style={{ cursor: "pointer" }}
                        />
                      </td>
                      <td>
                        <input
                          type="checkbox"
                          id={`download-checkbox-${index}`}
                          checked={right.download === 1}
                          onChange={() => handleCheckboxChange(index, 'download')}
                          style={{ cursor: "pointer" }}
                        />
                      </td>
                      <td>
                        <input
                          type="checkbox"
                          id={`upload-checkbox-${index}`}
                          checked={right.upload === 1}
                          onChange={() => handleCheckboxChange(index, 'upload')}
                          style={{ cursor: "pointer" }}
                        />
                      </td>
                      <td>
                        <input
                          type="checkbox"
                          id={`approve-checkbox-${index}`}
                          checked={right.approve === 1}
                          onChange={() => handleCheckboxChange(index, 'approve')}
                          style={{ cursor: "pointer" }}
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <br />
            <button onClick={handleSave} className="btn btn-primary">
              Save Access Rights
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddUserRight;
