import React, { useEffect, useState } from "react";
import Breadcrumbs from "../../core/breadcrumbs";
import { Link } from "react-router-dom";
import { Filter, Sliders, Edit, Trash2 } from "react-feather";
import ImageWithBasePath from "../../core/img/imagewithbasebath";
import Select from "react-select";
import { Table } from "antd";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import ExpenseCategoryModal from "../../core/modals/FinanceAccount/expenseCategoryModal";
import { Zap } from "feather-icons-react/build/IconComponents";
import { BASE_URL } from "../../environment";
import { API_BASE_URL } from "../../../src/environment";
const ExpenseCategory = () => {

  const [isFilterVisible, setIsFilterVisible] = useState(false);
  const [selectedExpenseCategoryId, setSelectedExpenseCategoryId] = useState(null);
  const toggleFilterVisibility = () => {
    setIsFilterVisible((prevVisibility) => !prevVisibility);
  };

  const [expenseCategories, setExpenseCategory] = useState([]);

  const [shouldReloadData, setShouldReloadData] = useState(false);

  const storedUserData = localStorage.getItem("user");
  const userData = JSON.parse(storedUserData);
  const id = userData?.data?.uid; //
  const [userRightssub, setUserRightssub] = useState([]);
  useEffect(() => {
    const fetchUserRightssub = async () => {
      try {
        const response = await fetch(`${API_BASE_URL}/userrightsub/sub/${id}`);
        if (!response.ok) {
          throw new Error("Failed to fetch user rights");
        }
        const data = await response.json();
        console.log("Fetched User Sub Data:", data.data);
        setUserRightssub(data.data || []); // Ensure it's always an array
      } catch (error) {
        console.error("Error fetching data:", error);
        setUserRightssub([]); // Set to empty array in case of error
      }
    };
  
    fetchUserRightssub(); // Fetch the data on component mount
  }, [id]);
  const hasJobRightWithAdd = userRightssub.some(right => right.right === 'expense-category' && right.adds === 1);
  const hasJobRightWithedit = userRightssub.some(right => right.right === 'expense-category' && right.updates === 1);
  //const hasJobRightWithview = userRightssub.some(right => right.right === 'expense-category' && right.viewes === 1);  
  const hasJobRightWithedel = userRightssub.some(right => right.right === 'expense-category' && right.deletes=== 1); 
    

  useEffect(() => {
    fetchExpenseCategory();
  }, [shouldReloadData]);

  const fetchExpenseCategory = async () => {
    try {
      const response = await fetch(`http://${BASE_URL}:8000/api/expenseCategories`);
      if (!response.ok) {
        throw new Error("Failed to fetch ExpenseCategory");
      }
      const data = await response.json();
      console.log("Fetched data:", data);
      setExpenseCategory(data.data.expenseCategory);
    } catch (error) {
      console.error("Error fetching expenseCategory:", error);
    }
  };

  const handleReloadData = () => {
    setShouldReloadData(!shouldReloadData);
  };

  const options = [
    { value: "sortByDate", label: "Sort by Date" },
    { value: "140923", label: "14 09 23" },
    { value: "110923", label: "11 09 23" },
  ];
  const optionsTwo = [
    { label: "Choose Customer Name", value: "" },
    { label: "Benjamin", value: "Benjamin" },
    { label: "Ellen", value: "Ellen" },
    { label: "Freda", value: "Freda" },
    { label: "Kaitlin", value: "Kaitlin" },
  ];

//   const countries = [
//     { label: "Choose Country", value: "" },
//     { label: "India", value: "India" },
//     { label: "USA", value: "USA" },
//   ];

  const columns = [
    {
      render: () => (
        <label className="checkboxs">
          <input type="checkbox" />
          <span className="checkmarks" />
        </label>
      ),
      width: 50,
    },

    {
      title: "Category Name",
      dataIndex: "CategoryName",
      width: 200,
      // sorter: (a, b) => a.categoryName.length - b.categoryName.length,
    },

    {
      title: "Description",
      dataIndex: "Description",
      width: 250,
      // sorter: (a, b) => a.description.length - b.description.length,
    },

    {
      title: "Action",
      dataIndex: "action",
      width:150,
      // sorter: (a, b) => a.createdby.length - b.createdby.length,
    },
  ];

  const MySwal = withReactContent(Swal);

  
  const showConfirmationAlert = (expenseCategoryId) => {
    MySwal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      showCancelButton: true,
      confirmButtonColor: "#00ff00",
      confirmButtonText: "Yes, delete it!",
      cancelButtonColor: "#ff0000",
      cancelButtonText: "Cancel",
    }).then((result) => {
      if (result.isConfirmed) {
        deleteExpenseCategory(expenseCategoryId);
        MySwal.fire({
          title: "Deleted!",
          text: "Your file has been deleted.",
          className: "btn btn-success",
          confirmButtonText: "OK",
          customClass: {
            confirmButton: "btn btn-success",
          },
        });
      } else {
        MySwal.close();
      }
    });
  };

  const deleteExpenseCategory = async (expenseCategoryId) => {
    try {
      const response = await fetch(`http://${BASE_URL}:8000/api/expenseCategories/${expenseCategoryId}`, {
        method: "DELETE",
      });
      if (response.ok) {
        handleReloadData();
        console.log("expenseCategory deleted successfully");
      } else {
        console.error("Failed to delete expenseCategory");
      }
    } catch (error) {
      console.error("Error deleting expenseCategory:", error);
    }
  };
  
  const handleLinkClick = async (expenseCategoryId) => {
    setSelectedExpenseCategoryId(expenseCategoryId);
  };

  const [searchQuery, setSearchQuery] = useState('');

  const handleSearchInputChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const filteredExpenceCategories = expenseCategories.filter(expenseCategory => {
    const searchLower = searchQuery.toLowerCase();
    return (
      expenseCategory.expenseCategoryName.toLowerCase().includes(searchLower)
    );
});
  return (
    <div className="page-wrapper">
      <div className="content">
      {hasJobRightWithAdd && (
          <Breadcrumbs
          maintitle="Supplier List "
          subtitle="Manage Your Supplier"
          addButton="Add New Supplier"
        />
      )}

        {/* /product list */}
        <div className="card table-list-card">
          <div className="card-body">
            <div className="table-top">
              <div className="search-set">
                <div className="search-input">
                  <input
                    type="search" 
                    className="form-control form-control-sm formsearch" 
                    value={searchQuery}
                    onChange={handleSearchInputChange}
                  />
                  <Link to className="btn btn-searchset">
                    <i data-feather="search" className="feather-search" />
                  </Link>
                </div>
              </div>
              <div className="search-path">
                <Link
                  className={`btn btn-filter ${
                    isFilterVisible ? "setclose" : ""
                  }`}
                  id="filter_search"
                >
                  <Filter
                    className="filter-icon"
                    onClick={toggleFilterVisibility}
                  />
                  <span onClick={toggleFilterVisibility}>
                    <ImageWithBasePath
                      src="assets/img/icons/closes.svg"
                      alt="img"
                    />
                  </span>
                </Link>
              </div>
              <div className="form-sort stylewidth">
                <Sliders className="info-img" />

                <Select
                  className="select "
                  options={options}
                  placeholder="Sort by Date"
                />
              </div>
            </div>
            {/* /Filter */}
            <div
              className={`card${isFilterVisible ? " visible" : ""}`}
              id="filter_inputs"
              style={{ display: isFilterVisible ? "block" : "none" }}
            >
              <div className="card-body pb-0">
                    <div className="row">
                      <div className="col-lg-3 col-sm-6 col-12">
                        <div className="input-blocks">
                          {/* <i data-feather="zap" className="info-img" /> */}
                          <Zap className="info-img" />
                          <Select
                            options={optionsTwo}
                            placeholder="Choose Category"
                          />
                        </div>
                      </div>
                      <div className="col-lg-9 col-sm-6 col-12">
                        <div className="input-blocks">
                          <Link className="btn btn-filters ms-auto">
                            <i
                              data-feather="search"
                              className="feather-search"
                            />{" "}
                            Search{" "}
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
            </div>
            {/* /Filter */}
            <div className="table-responsive">
            <Table
                  className="table datanew"
                  columns={columns}
                  dataSource={filteredExpenceCategories.map((expenseCategory) => ({
                    key: expenseCategory._id,
                    CategoryName: expenseCategory.expenseCategoryName,
                    Description: expenseCategory.expenseCategoryDescription,
                    action: (
                      <td className="action-table-data">
                        <div className="edit-delete-action">
                          <div className="input-block add-lists"></div>
                          {hasJobRightWithedit && (
                               <Link
                               className="me-2 p-2"
                               to="#"
                               data-bs-toggle="modal"
                               data-bs-target="#edit-units"
                               onClick={() => handleLinkClick(expenseCategory._id)}
                               >
                               <Edit className="feather-edit" />
                              </Link>
                          )}
                          {hasJobRightWithedel && (
                              <Link
                              className="confirm-text p-2"
                              to="#"
                              onClick={() => showConfirmationAlert(expenseCategory._id)}
                              >
                              <Trash2 className="feather-trash-2" />
                              </Link>
                          )}
                        </div>
                      </td>
                    ),
                  }))}
                  pagination={false}
                />
            </div>
          </div>
        </div>
        {/* /product list */}
      </div>

      <ExpenseCategoryModal onExpenseCategoryAdded={handleReloadData} expenseCategoryId={selectedExpenseCategoryId}/>
    </div>
  );
};

export default ExpenseCategory;
