import axios from "axios";
import { BASE_URL } from "../environment";

export const getLeavesEmployee = async () => {
  try {
    const response = await axios.get(
      `http://${BASE_URL}:8000/api/employee-leave`
    );
    console.log("Leave Employee Fetched..", response.data.data);
    return response.data.data;
  } catch (error) {
    console.log("Error fetching Leave Employees", error.message);
  }
};

export const getSingleLeaveEmployee = async (id) => {
  try {
    const response = await axios.get(
      `http://${BASE_URL}:8000/api/employee-leave/${id}`
    );
    console.log("Single Leave Employee Fetched..", response.data.data);
  } catch (error) {
    console.log("Error fetching Single Leave Employees", error.message);
  }
};

// Update the function to accept necessary parameters for the post request
export const createLeaveEmployee = async (leaveData) => {
  try {
    const response = await axios.post(
      `http://${BASE_URL}:8000/api/employee-leave`,
      {
        leavetype: leaveData.leavetype,
        leaveDays: leaveData.leaveDays, // Array of dates and days (Full Day / Half Day)
        reason: leaveData.reason,
        startDate: leaveData.startDate,
        endDate: leaveData.endDate,
        approval: leaveData.approval || false, // Default to false if not provided
      }
    );
    return response.data;
  } catch (error) {
    console.error("Failed to create employee leave:", error.message);
    throw error;
  }
};
export const updateLeaveEmployee = async (id) => {
  try {
    const response = await axios.put(
      `http://${BASE_URL}:8000/api/employee-leave/${id}`
    );
    return response.data;
  } catch (error) {
    console.error("Failed to Update employee leave:", error.message);
    throw error;
  }
};
export const deleteLeaveEmployee = async (id) => {
  try {
    const response = await axios.delete(
      `http://${BASE_URL}:8000/api/employee-leave/${id}`
    );
    return response.data;
  } catch (error) {
    console.error("Failed to Update employee leave:", error.message);
    throw error;
  }
};
