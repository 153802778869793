import React, {useEffect, useState} from "react";
import {createCustomer, updateCustomer} from "../../../service/ApiServices";
import PropTypes from "prop-types";
import Swal from "sweetalert2";
import "./CustomerModel.css";
// import Select from 'react-select';

const CustomerModal = ({ loadCustomers ,selectedCustomer,setCustomers}) => {
    console.log("Selected Customer",selectedCustomer)
    //             setCustomers((prevCustomers)=>[...prevCustomers,formData])

    console.log(setCustomers);

    const [formData, setFormData] = useState({
        customerName: "",
        customerEmail: "",
        customerPhone: "",
        customerAddress: "",
        customerCity: "",
        customerCountry: "",

    });


    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };


    useEffect(() => {
        if (selectedCustomer) {
            setFormData(selectedCustomer);
        }
    }, [selectedCustomer]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            console.log("Create custoemr", formData)
            const response = await createCustomer(formData);
            setCustomers(response.data.data.customersList);
            console.log("Response", response)
            await showSuccess("Customer creation success")
            console.log("------", loadCustomers)
            await loadCustomers();

            // setStatusByCustomerListUpdate(true);
            setTimeout(()=>{

            },1000)
            setFormData({
                customerName: "",
                customerEmail: "",
                customerPhone: "",
                customerAddress: "",
                customerCity: "",
                customerCountry: "",
                customerDescription: "",
                customerImage: null,
            });
        } catch (error) {
            console.error("Error adding customer:", error);
        }
        
    };

    const handleEdit = async (e) => {
        e.preventDefault();
        try {
            console.log("Edit custoemr", formData)
            const response = await updateCustomer(formData,selectedCustomer._id);
            console.log("Response", response)
            await showSuccess("Customer Modification success")
            console.log("------", loadCustomers)
            loadCustomers();
            setFormData({
                customerName: "",
                customerEmail: "",
                customerPhone: "",
                customerAddress: "",
                customerCity: "",
                customerCountry: "",
                customerDescription: "",
                customerImage: null,
            });
        } catch (error) {
            console.error("Error adding customer:", error);
        }
    };

    const showSuccess = (msg) => {
        return Swal.fire({
            title: "Good job!",
            text: msg,
            icon: "success"
        });
    }

    // const handleUpdateSubmit = async (e) => {
    //     e.preventDefault();
    //     try {
    //         const response = await updateCustomer(customerId, formData);
    //         console.log("Customer updated successfully:", response);
    //         setFormData({
    //             // Clear form data if needed
    //             customerName: "",
    //             customerEmail: "",
    //             customerPhone: "",
    //             customerAddress: "",
    //             customerCity: "",
    //             customerCountry: "",
    //             customerDescription: "",
    //             customerImage: null,
    //         });
    //     } catch (error) {
    //         console.error("Error updating customer:", error);
    //     }
    // };

    // const countriesOptions = [
    //   { value: "choose", label: "Choose" },
    //   { value: "unitedKingdom", label: "United Kingdom" },
    //   { value: "unitedStates", label: "United States" },
    // ];

    // const varrelOptions = [{ value: "varrel", label: "Varrel" }];

    // const germanyOptions = [
    //   { value: "germany", label: "Germany" },
    //   { value: "unitedStates", label: "United States" },
    // ];

    // fetch country name and set it to country option
    // const [countries, setCountries] = useState([]);

    // useEffect(() => {
    //   const fetchCountries = async () => {
    //     try {
    //       const response = await fetch('https://restcountries.com/v3.1/all');
    //       const data = await response.json();
    //       console.log(data);
    //       const processedCountryOptions = data.map(country => ({
    //         value: country.name.common,
    //         label: country.name.common
    //       }));
    //       setCountries(processedCountryOptions);
    //     } catch (error) {
    //       console.error('Error fetching countries:', error);
    //     }
    //   };

    //   fetchCountries();
    // }, []);

    // const countriesSet = [
    //   { value: "Choose", label: "Choose" },

    //   ...countries
    // ];
    return (
        <>
            {/* Add Customer */}
            <div className="modal fade" id="add-units">
                <div className="modal-dialog modal-dialog-centered custom-modal-two">
                    <div className="modal-content">
                        <div className="page-wrapper-new p-0">
                            <div className="content">
                                <div className="modal-header border-0 custom-modal-header">
                                    <div className="page-title">
                                        <h4>Add Customer</h4>
                                    </div>
                                    <button
                                        type="button"
                                        className="close"
                                        data-bs-dismiss="modal"
                                        aria-label="Close"
                                    >
                                        <span aria-hidden="true">×</span>
                                    </button>
                                </div>
                                <div className="modal-body custom-modal-body">
                                    <form onSubmit={handleSubmit}>
                                        <div className="modal-title-head people-cust-avatar">
                                            <div className="row">
                                                <div className="col-lg-12 pe-0">
                                                    <div className="mb-3">
                                                        <label className="form-label">Customer Name</label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            name="customerName"
                                                            value={formData.customerName}
                                                            onChange={handleChange}
                                                            required
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-lg-6 pe-0">
                                                    <div className="mb-3">
                                                        <label className="form-label">Email</label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            name="customerEmail"
                                                            value={formData.customerEmail}
                                                            onChange={handleChange}
                                                            required
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-lg-6 pe-0">
                                                    <div className="input-blocks">
                                                        <label className="mb-2">Phone</label>
                                                        <input
                                                            // className="form-control form-control-lg group_formcontrol"
                                                            // id="phone"
                                                            name="customerPhone"
                                                            type="text"
                                                            value={formData.customerPhone}
                                                            onChange={handleChange}
                                                            required
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-lg-12 pe-0">
                                                    <div className="mb-3">
                                                        <label className="form-label">Address</label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            name="customerAddress"
                                                            value={formData.customerAddress}
                                                            onChange={handleChange}
                                                            required
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-lg-6 pe-0">
                                                    <div className="mb-3">
                                                        <label className="form-label">City</label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            name="customerCity"
                                                            value={formData.customerCity}
                                                            onChange={handleChange}
                                                            required
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-lg-6 pe-0">
                                                    <div className="mb-3">
                                                        <label className="form-label">Country</label>
                                                        {/* <Select
                            className="select"
                            options={countriesSet}
                            name="customerCountry"
                            onChange={(selectedOption) => setFormData((prevData) => ({
                              ...prevData,
                              customerCountry: selectedOption.value,
                            }))}
                            // value={formData.customerCountry}
                            placeholder="Choose"
                          /> */}
                                                        {/* <Select
                            // type="text"
                            className="select"
                            options={countriesOptions}
                            name="customerCountry"
                            value={formData.customerCountry}
                            onChange={(selectedOption) =>
                              setFormData((prevData) => ({
                                ...prevData,
                                country: selectedOption,
                              }))
                            }
                          /> */}
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            name="customerCountry"
                                                            value={formData.customerCountry}
                                                            onChange={handleChange}
                                                            required
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="modal-footer-btn">
                                                <button
                                                    type="button"
                                                    className="btn btn-cancel me-2"
                                                    data-bs-dismiss="modal"
                                                >
                                                    Cancel
                                                </button>
                                                <button type="submit"
                                                        className="btn btn-submit"
                                                        data-bs-dismiss="modal">
                                                    Submit
                                                </button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* /Add Customer */}

            {/* Edit Customer */}
            {selectedCustomer &&
                <div className="modal fade" id="edit-units">
                    <div className="modal-dialog modal-dialog-centered custom-modal-two">
                        <div className="modal-content">
                            <div className="page-wrapper-new p-0">
                                <div className="content">
                                    <div className="modal-header border-0 custom-modal-header">
                                        <div className="page-title">
                                            <h4>Edit Customer</h4>
                                        </div>
                                        <button
                                            type="button"
                                            className="close"
                                            data-bs-dismiss="modal"
                                            aria-label="Close"
                                        >
                                            <span aria-hidden="true">×</span>
                                        </button>
                                    </div>
                                    <div className="modal-body custom-modal-body">
                                        <form onSubmit={handleEdit}>
                                            <div className="modal-title-head people-cust-avatar">
                                                <div className="row">
                                                    <div className="col-lg-12 pe-0">
                                                        <div className="mb-3">
                                                            <label className="form-label">Customer Name</label>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                name="customerName"
                                                                value={formData.customerName}
                                                                onChange={handleChange}
                                                                required
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6 pe-0">
                                                        <div className="mb-3">
                                                            <label className="form-label">Email</label>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                name="customerEmail"
                                                                value={formData.customerEmail}
                                                                onChange={handleChange}
                                                                required
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6 pe-0">
                                                        <div className="input-blocks">
                                                            <label className="mb-2">Phone</label>
                                                            <input
                                                                // className="form-control form-control-lg group_formcontrol"
                                                                // id="phone"
                                                                name="customerPhone"
                                                                type="text"
                                                                value={formData.customerPhone}
                                                                onChange={handleChange}
                                                                required
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-12 pe-0">
                                                        <div className="mb-3">
                                                            <label className="form-label">Address</label>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                name="customerAddress"
                                                                value={formData.customerAddress}
                                                                onChange={handleChange}
                                                                required
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6 pe-0">
                                                        <div className="mb-3">
                                                            <label className="form-label">City</label>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                name="customerCity"
                                                                value={formData.customerCity}
                                                                onChange={handleChange}
                                                                required
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6 pe-0">
                                                        <div className="mb-3">
                                                            <label className="form-label">Country</label>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                name="customerCountry"
                                                                value={formData.customerCountry}
                                                                onChange={handleChange}
                                                                required
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="modal-footer-btn">
                                                    <button
                                                        type="button"
                                                        className="btn btn-cancel me-2"
                                                        data-bs-dismiss="modal"
                                                    >
                                                        Cancel
                                                    </button>
                                                    <button type="submit"
                                                            className="btn btn-submit"
                                                            data-bs-dismiss="modal">
                                                        Submit
                                                    </button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }

            {/* /Edit Customer */}

        </>
    );
};

CustomerModal.propTypes = {
    loadCustomers: PropTypes.func.isRequired,
    selectedCustomer: PropTypes.object.isRequired,
    setCustomers:PropTypes.array.isRequired
};


export default CustomerModal;
