import { Modal, Popconfirm, Spin, Table } from 'antd';
import React, { useEffect, useState } from 'react';
import { PlusCircle } from 'react-feather';
import { Link } from 'react-router-dom';
import { createGRN, deleteGRN, getAllGRNsMethod, getAllProducts, getAllSuppliers, getGRNno } from '../../../service/ApiServices';
import Swal from 'sweetalert2';
import { DatePicker } from 'antd';
const { RangePicker } = DatePicker;
import dayjs from 'dayjs';

import { API_BASE_URL } from "../../../../src/environment";



const GRN = () => {
    const startOfCurrentMonth = dayjs().startOf('month');
    const endOfCurrentMonth = dayjs().endOf('month');
    const [search, setSearch] = useState("");
    const [selectedRange, setSelectedRange] = useState([
        startOfCurrentMonth,
        endOfCurrentMonth
    ]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [addData, setAddData] = useState([])
    const [products, setProducts] = useState([])
    const [suppliers, setSuppliers] = useState([])
    const [selectedProduct, setSelectedProduct] = useState("")
    const [selectedSupplier, setSelectedSupplier] = useState("")
    const [grnDate, setGrnDate] = useState(null)
    const [expireDate, setExpireDate] = useState(null)
    const [grnNo, setGrnNo] = useState(null)
    const [qty, setQty] = useState(0)
    const [costPrice, setCostPrice] = useState(0)
    const [loading, setLoading] = useState(false)
    const [success, setSuccess] = useState(false)
    const [deleted, setDeleted] = useState(false)
    const [viewGRN, setViewGRN] = useState("")
    const [editGRN, setEditGRN] = useState("")
    const [grnsData, setGrnsData] = useState({
        data: [],
        page: 1,
        limit: 10,
        total: 0
    });



    const storedUserData = localStorage.getItem("user");
    const userData = JSON.parse(storedUserData);
    const id = userData?.data?.uid; //
    const [userRightssub, setUserRightssub] = useState([]);
    useEffect(() => {
      const fetchUserRightssub = async () => {
        try {
          const response = await fetch(`${API_BASE_URL}/userright/${id}`);
          if (!response.ok) {
            throw new Error("Failed to fetch user rights");
          }
          const data = await response.json();
          console.log("Fetched User Sub Data:", data.data);
          setUserRightssub(data.data || []); // Ensure it's always an array
        } catch (error) {
          console.error("Error fetching data:", error);
          setUserRightssub([]); // Set to empty array in case of error
        }
      };
    
      fetchUserRightssub(); // Fetch the data on component mount
    }, [id]);
    const hasJobRightWithAdd = userRightssub.some(right => right.right === 'grn' && right.adds === 1);
    const hasJobRightWithedit = userRightssub.some(right => right.right === 'grn' && right.updates === 1);
   // const hasJobRightWithedown = userRightssub.some(right => right.right === 'grn' && right.fdownload === 1);  
    const hasJobRightWithedel = userRightssub.some(right => right.right === 'grn' && right.deletes === 1); 
    const hasJobRightWitheview = userRightssub.some(right => right.right === 'grn' && right.viewes === 1); 

    const enableEditing = (record) => {
        const updatedData = addData.map(item =>
            item.product._id === record.product._id ? {
                ...item,
                isEditing: true,
                initialQty: item.qty,
                initialExpireData: item.expireDate,
                initialCostPrice: Number(item.costPrice),
                initialTotal: item.total
            } : item
        );
        console.log(updatedData)
        setAddData(updatedData);
    };

    const handleQtyChange = (e, record) => {
        const { value } = e.target;
        if (value > 0) {
            const updatedData = addData.map(item =>
                item.product._id === record.product._id
                    ? { ...item, qty: Number(value), total: Number(value) * item.costPrice }
                    : item
            );
            setAddData(updatedData);
        } else {
            showWarning("Quantity cannot be 0 or negative");
        }
    };

    const handleCostPriceChange = (e, record) => {
        const { value } = e.target;
        if (value > 0) {
            const updatedData = addData.map(item =>
                item.product._id === record.product._id
                    ? { ...item, costPrice: Number(value), total: item.qty * Number(value) }
                    : item
            );
            setAddData(updatedData);
        } else {
            showWarning("Cost Price cannot be 0 or negative");
        }
    };

    const handleExpireDateChange = (date, record) => {
        const updatedData = addData.map(item =>
            item.product._id === record.product._id
                ? { ...item, expireDate: date.format("YYYY-MM-DD") }
                : item
        );
        setAddData(updatedData);
    };

    const saveEdit = (record) => {
        const updatedData = addData.map(item =>
            item.product._id === record.product._id ? { ...item, isEditing: false } : item
        );
        setAddData(updatedData);
    };

    const deleteRow = (record) => {
        const newData = addData.filter(data => data.product._id !== record.product._id);
        setAddData(newData);
    };

    const cancelEdit = (record) => {
        const updatedData = addData.map(item => {
            if (item.product._id === record.product._id) {
                return {
                    ...item,
                    isEditing: false,
                    qty: item.initialQty,
                    costPrice: Number(item.initialCostPrice),
                    expireDate: item.initialExpireData,
                    total: item.initialTotal
                };
            }
            return item;
        });
        setAddData(updatedData);
    };

    useEffect(() => {
        const initialCalls = async () => {
            getAllProducts({ setData: setProducts })
            getAllSuppliers({ setData: setSuppliers })
        }
        initialCalls()
    }, [])

    useEffect(() => {
        if (success) {
            showSuccess()
            handleCancel()
            getAllGRNsMethod({
                setGRNdata: setGrnsData,
                setLoading: setLoading,
                params: { page: grnsData.page, limit: grnsData.limit },
                search,
                startDate: selectedRange[0].format("YYYY-MM-DD"),
                endDate: selectedRange[1].format("YYYY-MM-DD")
            });
        }
        if (deleted) {
            Swal.fire({
                title: "Good job!",
                text: "You Successfully Deleted GRN!",
                icon: "success"
            });
            getAllGRNsMethod({
                setGRNdata: setGrnsData,
                setLoading: setLoading,
                params: { page: grnsData.page, limit: grnsData.limit },
                search,
                startDate: selectedRange[0].format("YYYY-MM-DD"),
                endDate: selectedRange[1].format("YYYY-MM-DD")
            });

        }
    }, [success, deleted])

    useEffect(() => {
        const handler = setTimeout(() => {
            getAllGRNsMethod({
                setGRNdata: setGrnsData,
                setLoading: setLoading,
                params: { page: grnsData.page, limit: grnsData.limit },
                search,
                startDate: selectedRange[0].format("YYYY-MM-DD"),
                endDate: selectedRange[1].format("YYYY-MM-DD")
            });
        }, 600);

        return () => {
            clearTimeout(handler);
        };
    }, [search, selectedRange]);

    useEffect(() => {
        if (viewGRN || editGRN) {
            setData(viewGRN || editGRN)
        }
    }, [viewGRN, editGRN])

    const setData = (data) => {
        setGrnDate(data.grnDate.split("T")[0])
        setSelectedSupplier(data.supplier._id)
        setAddData(data.items)
        setGrnNo(data.grnNumber)
    }

    const handleOk = () => {
        setIsModalOpen(false);
    };

    const handleCancel = () => {
        handleReset()
        setViewGRN("")
        setEditGRN("")
        setIsModalOpen(false);
    };

    const columns = [
        {
            title: "Product Name",
            dataIndex: ["product", "productName"],
        },
        {
            title: "Qty",
            dataIndex: "qty",
            render: (text, record) => (
                record.isEditing ? (
                    <input
                        className="form-control"
                        type="number"
                        min="1"
                        value={record.qty}
                        onChange={(e) => handleQtyChange(e, record)}
                    />
                ) : (
                    text
                )
            ),
        },
        {
            title: "Cost Price",
            dataIndex: "costPrice",
            render: (text, record) => (
                record.isEditing ? (
                    <input
                        className="form-control"
                        type="number"
                        min="0"
                        value={record.costPrice}
                        onChange={(e) => handleCostPriceChange(e, record)}
                    />
                ) : (
                    text
                )
            ),
        },
        {
            title: "Total",
            dataIndex: "total",
        },
        {
            title: "Expire Date",
            dataIndex: "expireDate",
            render: (text, record) => (
                record.isEditing ? (
                    <DatePicker
                        className="form-control"
                        value={dayjs(record.expireDate)}
                        onChange={(date) => handleExpireDateChange(date, record)}
                        format="YYYY-MM-DD"
                    />
                ) : (
                    new Date(text).toLocaleDateString()
                )
            ),
        },
        {
            title: 'Actions',
            dataIndex: 'actions',
            key: 'actions',
            render: (text, record) => (
                <div>
                    {record.isEditing ? (
                        <>
                            <Link
                                onClick={() => saveEdit(record)}
                                className="me-2"
                            >
                                <i data-feather="check" className="feather-check"></i>
                            </Link>
                            <Link
                                disabled={viewGRN}
                                onClick={() => cancelEdit(record)}
                                className="me-2"
                            >
                                <i data-feather="x-circle" className="feather-x-circle"></i>
                            </Link>
                        </>
                    ) : (
                        <Link
                            disabled={viewGRN}
                            onClick={() => enableEditing(record)}
                            className="me-2"
                        >
                            <i data-feather="download" className="feather-edit"></i>
                        </Link>
                    )}
                    <Link
                        disabled={viewGRN}
                        onClick={() => deleteRow(record)}
                        className="me-2"
                    >
                        <i data-feather="download" className="feather-trash"></i>
                    </Link>

                </div>

            )
        },
    ];


    const grnsColumns = [
        {
            title: 'GRN Number',
            dataIndex: 'grnNumber',
            key: 'grnNumber',
        },
        {
            title: 'Supplier Name',
            dataIndex: ['supplier', 'supplierName'],
            key: 'supplier.supplierName',
        },
        {
            title: 'GRN Date',
            dataIndex: 'grnDate',
            key: 'grnDate',
            render: (grnDate) => new Date(grnDate).toLocaleDateString()
        },
        {
            title: 'Total (Rs.)',
            key: 'totalValue',
            render: (text, record) => {
                const totalValue = record.items.reduce((sum, item) => sum + item.total, 0);
                return totalValue;
            },
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status'
        },
        {
            title: 'Action',
            key: 'action',
            render: (text, record) => (
                <td className="action-table-data">
                    
                    {!hasJobRightWithedit && (
                        <Link
                        className="me-2"
                        onClick={() => {
                            setEditGRN(record)
                            setIsModalOpen(true)
                        }}
                        >
                            <i data-feather="edit" className="feather-edit"></i>
                        </Link>
                     )}


                    {!hasJobRightWithedel && (
                          <Popconfirm
                          title="Delete the GRN"
                          description="Are you sure to delete this GRN?"
                          onConfirm={() => {
                              deleteGRN({
                                  id: record._id,
                                  setDeleted
                              })
                          }}
                          onCancel={() => {
                              console.log("c")
                          }}
                          okText="Yes"
                          cancelText="No"
                      >
                          <Link className="confirm-text me-2 p-2" to="#">
                              <i data-feather="trash-2"
                                  className="feather-trash-2"
  
                              ></i>
                          </Link>
                      </Popconfirm>  

                     )}

                    {!hasJobRightWitheview && (
                       
                    <Link className="me-2 p-2" to="#"
                    onClick={() => {
                            setViewGRN(record)
                            setIsModalOpen(true)
                        }}
                    >
                        <i data-feather="download" className="feather-eye"></i>
                    </Link>
                     )}
                </td>
            ),
        }
    ];

    const handleSubmit = () => {
        if (grnDate === null || grnDate === "" || selectedSupplier === "") {
            showWarning("Please fill all the fields")
            return
        }
        if (addData.length === 0) {
            showWarning("Please add at least 1 product")
            return
        }
        const submitData = {
            id: editGRN?._id || null,
            grnDate,
            supplier: selectedSupplier,
            items: addData.map((data) => ({
                product: data.product._id,
                qty: Number(data.qty),
                costPrice: Number(data.costPrice),
                total: data.total,
                expireDate: data.expireDate
            })),
            grnNumber: grnNo
        }
        createGRN({
            data: submitData,
            setSuccess,
            setLoading
        })
    }

    const showSuccess = () => {
        return Swal.fire({
            title: "Good job!",
            text: "You Successfully Saved GRN!",
            icon: "success"
        });
    }
    const showWarning = (str) => {
        return Swal.fire({
            title: "Error",
            text: str,
            icon: "error",
        });
    }
    const handleReset = () => {
        setAddData([])
        setSelectedProduct("")
        setQty(0)
        setGrnDate("")
        setSelectedSupplier("")
    }

    const handleTableChange = (pagination) => {
        getAllGRNsMethod({
            setGRNdata: setGrnsData,
            setLoading: setLoading,
            params: { page: pagination.current, limit: pagination.pageSize },
            search,
            startDate: selectedRange[0].format("YYYY-MM-DD"),
            endDate: selectedRange[1].format("YYYY-MM-DD")
        });
    };

    const onChange = (dates) => {
        if (dates === null) {
            setSelectedRange([startOfCurrentMonth, endOfCurrentMonth])
            return
        }
        setSelectedRange(dates);
    };

    return (
        <div className="page-wrapper">
            <div className="content">
                <div className="page-header">
                    <div className="add-item d-flex">
                        <div className="page-title">
                            <h4>GRN</h4>
                            <h6>Manage your GRNS</h6>
                        </div>
                    </div>
                    <div className="page-btn" onClick={() => {
                        getGRNno({ setData: setGrnNo })
                        setIsModalOpen(true)
                    }}>
                        
                        {!hasJobRightWithAdd && (
                           <a className="btn btn-added">
                            <PlusCircle className="me-2" />
                            Add New GRN
                        </a>
                        )}
                    </div>
                </div>

                <div className="card table-list-card">
                    <div className="card-body">
                        <div className="table-top">
                            <div className="search-set">
                                <div className="search-input">
                                    <input
                                        type="search"
                                        className="form-control"
                                        placeholder="Search"
                                        value={search}
                                        onChange={(e) => setSearch(e.target.value)}
                                    />
                                    <Link className="btn btn-searchset">
                                        <i data-feather="search" className="feather-search" />
                                    </Link>
                                </div>
                                <RangePicker onChange={onChange} value={selectedRange} format="YYYY-MM-DD" />
                                {/*<div className="wordset">*/}
                                {/*    <ul>*/}
                                {/*        <li>*/}
                                {/*            <a href="#" data-bs-toggle="tooltip" data-bs-placement="top" title="pdf">*/}
                                {/*                <img src="assets/img/icons/pdf.svg" alt="pdf" />*/}
                                {/*            </a>*/}
                                {/*        </li>*/}
                                {/*        <li>*/}
                                {/*            <a href="#" data-bs-toggle="tooltip" data-bs-placement="top" title="excel">*/}
                                {/*                <img src="assets/img/icons/excel.svg" alt="excel" />*/}
                                {/*            </a>*/}
                                {/*        </li>*/}
                                {/*        <li>*/}
                                {/*            <a href="#" data-bs-toggle="tooltip" data-bs-placement="top" title="print">*/}
                                {/*                <img src="assets/img/icons/printer.svg" alt="print" />*/}
                                {/*            </a>*/}
                                {/*        </li>*/}
                                {/*    </ul>*/}
                                {/*</div>*/}
                            </div>

                        </div>
                        <div className="table-responsive">
                            <Table
                                loading={loading}
                                columns={grnsColumns}
                                dataSource={grnsData.data}
                                pagination={{
                                    current: grnsData.page,
                                    pageSize: grnsData.limit,
                                    total: grnsData.total
                                }}
                                rowKey="value"
                                onChange={handleTableChange}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <Modal
                zIndex={200000}
                open={isModalOpen}
                onOk={handleOk}
                width="85vw"
                onCancel={handleCancel}
                footer={null}
                maskClosable={false}
                closable={false}
            >
                <div className="modal-content">
                    <div className="modal-header border-0 custom-modal-header">
                        {
                            viewGRN ? <div className="page-title">
                                <h4>{"View GRN"}</h4>
                            </div> :
                                <div className="page-title">
                                    <h4>{editGRN ? "Edit GRN" : "Create GRN"}</h4>
                                    <p className="mb-0">Fill in the required fields to create a GRN</p>
                                </div>
                        }
                    </div>
                    <div className="modal-body custom-modal-body">
                        <div className="row">
                            <div className="col-lg-4 col-sm-6 col-12">
                                <div className="input-blocks">
                                    <label>GRN No</label>
                                    <input
                                        type="text"
                                        disabled
                                        className="form-control"
                                        value={grnNo}
                                        onChange={(e) => setGrnNo(e.target.value)}
                                    />
                                </div>
                            </div>
                            <div className="col-lg-4 col-sm-6 col-12">
                                <div className="input-blocks">
                                    <label>GRN Date</label>
                                    <input
                                        type="date"
                                        className="form-control"
                                        value={grnDate}
                                        onChange={(e) => setGrnDate(e.target.value)}
                                        disabled={viewGRN}
                                    />
                                </div>
                            </div>
                            <div className="col-lg-4 col-sm-6 col-12">
                                <div className="input-blocks">
                                    <label>Supplier</label>
                                    <select
                                        className="form-control"
                                        value={selectedSupplier}
                                        onChange={(e) => setSelectedSupplier(e.target.value)}
                                        disabled={viewGRN}
                                    >
                                        <option value={""}>Select a Supplier</option>
                                        {
                                            suppliers.map((supplier) => (
                                                <option key={supplier._id} value={supplier._id}>{supplier.supplierName}</option>
                                            ))
                                        }
                                    </select>
                                </div>
                            </div>
                            <div className='row'>
                                <div className="col-lg-3 col-sm-6 col-6">
                                    <div className="input-blocks">
                                        <label>Product</label>
                                        <select
                                            className="form-control"
                                            value={selectedProduct}
                                            onChange={(e) => setSelectedProduct(e.target.value)}
                                            disabled={viewGRN}
                                        >
                                            <option value={""}>Select a Product</option>
                                            {
                                                products?.map((product) => (
                                                    <option key={product._id}
                                                        value={product._id}>{product.productName}</option>
                                                ))
                                            }
                                        </select>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-sm-6 col-6">
                                    <div className="input-blocks">
                                        <label>Qty</label>
                                        <input
                                            type="number"
                                            className="form-control"
                                            value={qty}
                                            onChange={(e) => setQty(e.target.value)}
                                            disabled={viewGRN}
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-3 col-sm-6 col-6">
                                    <div className="input-blocks">
                                        <label>Unit Price</label>
                                        <input
                                            type="number"
                                            className="form-control"
                                            value={costPrice}
                                            onChange={(e) => setCostPrice(e.target.value)}
                                            disabled={viewGRN}
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-3 col-sm-6 col-6">
                                    <div className="input-blocks">
                                        <label>Expire Date</label>
                                        <input
                                            type="date"
                                            className="form-control"
                                            value={expireDate}
                                            onChange={(e) => setExpireDate(e.target.value)}
                                            disabled={viewGRN}
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-4 col-sm-6 col-3 mt-1">
                                    <button className="btn btn-primary"
                                        disabled={!selectedProduct || !qty || qty <= 0 || expireDate === "" || expireDate === null || parseFloat(costPrice) <= 0 || costPrice === ""}
                                        onClick={() => {
                                            const isProductExistsTable = addData.find((data) => data.product._id === selectedProduct)
                                            if (isProductExistsTable) {
                                                showWarning("Product already exists in the table")
                                                return
                                            }
                                            setAddData([...addData, {
                                                product: products.find((product) => product._id === selectedProduct),
                                                qty: qty,
                                                costPrice: costPrice,
                                                total: Number(costPrice) * Number(qty),
                                                expireDate: expireDate
                                            }])
                                            setExpireDate("")
                                            setQty(0)
                                            setSelectedProduct("")
                                            setCostPrice(0)
                                        }}>Add To Table
                                    </button>
                                </div>
                            </div>
                            <Table columns={viewGRN ? columns.slice(0, 5) : columns} dataSource={addData}
                                rowKey="value" />
                        </div>
                    </div>
                    <div className="" style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center"
                    }}>
                        <button
                            type="button"
                            className="btn btn-cancel"
                            onClick={handleCancel}
                        >
                            Close
                        </button>
                        {
                            !viewGRN && <div className="">
                                <button
                                    type="button"
                                    className="btn btn-reset me-2"
                                    onClick={() => {
                                        handleReset()
                                    }}
                                >
                                    Reset
                                </button>
                                <button type='button'

                                    onClick={handleSubmit} className="btn btn-submit ml-0" disabled={loading || addData.some((item) => item?.isEditing === true)}>
                                    {loading ? <Spin size='small' /> : "Save"}
                                </button>
                            </div>
                        }
                    </div>
                </div>
            </Modal>
        </div>
    );
};

export default GRN;
