import axios from "axios";

export const createPos = async ({
  orderTax,
  discount,
  shipping,
  grandTotal,
  customer,
  productprices,

  
}) => {
  return await axios.post("http://localhost:8000/api/pos", {
    orderTax,
    discount,
    shipping,
    grandTotal,
    customer,
    productprices,
    
  });
};

export const getposByID = async (id) => {
  const response = await fetch(`http://localhost:8000/api/pos/${id}`);
  if (!response.ok) {
    throw new Error("Failed to fetch pos details");
  }
  return response.json();
};
