import React, { useEffect, useState } from "react";
import { createLeaveEmployee } from "../../../service/leavesEmployeeApiService";
import { getAllLeaveTypes } from "../../../service/leaveApiServices/leaveTypesApi";
import TextEditor from "../../../feature-module/inventory/texteditor";
import Select from "react-select";
import { DatePicker } from "antd";
const { RangePicker } = DatePicker;

const AddLeaveEmployee = () => {
  const leaveDayOptions = [
    { value: "Choose Status", label: "Choose Status" },
    { value: "Full Day", label: "Full Day", dayValue: 1 },
    { value: "Half Day", label: "Half Day", dayValue: 0.5 },
  ];

  const [selectedDate, setSelectedDate] = useState([null, null]);
  const [dateRange, setDateRange] = useState([]);
  const [selectedLeaveType, setSelectedLeaveType] = useState(null);
  const [reason, setReason] = useState();
  const [leaveDays, setLeaveDays] = useState([]);
  const [leaveType, setLeaveType] = useState([]); //[funeral, sick leave]
  //  const [minLeaveCount, setMinLeaveCount] = useState(null);

  // Handle Date Change
  const formatDate = (date) => {
    const d = new Date(date);
    let month = "" + (d.getMonth() + 1);
    let day = "" + d.getDate();
    const year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  };

  useEffect(() => {
    fetchLeaveTypes();
  }, []);

  const fetchLeaveTypes = async () => {
    try {
      const leaveTypeData = await getAllLeaveTypes({
        search: "",
        setLoading: () => {}, // Optional loading state
      });

      //console.log("Available LeaveTypes:", leaveTypeData);

      if (leaveTypeData) {
        const options = leaveTypeData.map((leave) => ({
          value: leave._id,
          label: leave.name,
          leaveCount: leave.minimumLeaveCount,
        }));

        setLeaveType([{ value: "Choose", label: "Choose" }, ...options]);
      }
    } catch (error) {
      console.error("Error fetching leave Types:", error);
    }
  };

  // Handle Date Change
  const handleDateChange = (dates) => {
    setSelectedDate(dates);
    if (dates && dates[0] && dates[1]) {
      const start = new Date(dates[0]);
      const end = new Date(dates[1]);

      const duration = Math.ceil((end - start) / (1000 * 60 * 60 * 24)) + 1;
      // Check if the selected date range is less than the minimum leave count
      if (selectedLeaveType) {
        // Get the leave count (minimum leave days) from the selected leave type
        const leaveCount = selectedLeaveType.leaveCount;

        // If the selected date range is less than the leave count, show an alert
        if (duration < leaveCount) {
          alert(`The selected date range must be at least ${leaveCount} days.`);
          return; // Prevent further processing if the duration is too short
        }
      }

      // Proceed to set the date range and leave days
      const daysArray = [];
      let dayCount = 1;
      let currentDate = new Date(start);
      while (currentDate.getTime() <= end.getTime()) {
        daysArray.push({
          day: `Day ${dayCount}`,
          date: formatDate(currentDate),
          leaveType: null,
        });
        currentDate.setDate(currentDate.getDate() + 1);
        dayCount++;
      }
      setDateRange(daysArray);
      setLeaveDays(daysArray);
    } else {
      setDateRange([]);
      setLeaveDays([]);
    }
  };

  // Handle Leave Type Change
  const handleLeaveTypeChange = (index, selectedOption) => {
    const updatedLeaveDays = [...leaveDays];
    updatedLeaveDays[index].leaveType = selectedOption.value;
    updatedLeaveDays[index].dayValue = selectedOption.dayValue;
    setLeaveDays(updatedLeaveDays);
  };

  // Calculate total leave days (sum of Full Day and Half Day values)
  const calculateTotalLeaveDays = () => {
    return leaveDays.reduce((total, day) => total + (day.dayValue || 0), 0); // Default to 0 if dayValue is not set
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const totalLeaveDays = calculateTotalLeaveDays();
    if (selectedLeaveType && totalLeaveDays < selectedLeaveType.leaveCount) {
      alert(
        `The total leave days must be at least ${selectedLeaveType.leaveCount}. Currently selected: ${totalLeaveDays}`
      );
      return;
    }

    // Prepare the leave data
    const leaveData = {
      leavetype: selectedLeaveType.value,
      leaveDays: leaveDays.map((day) => ({
        date: day.date,
        Day: day.leaveType, // Full Day or Half Day
      })),
      reason: reason,
      startDate: selectedDate[0].format("YYYY-MM-DD"),
      endDate: selectedDate[1].format("YYYY-MM-DD"),
      approval: false, // Set default approval
    };

    try {
      const response = createLeaveEmployee(leaveData);
      console.log("Leave submitted successfully:", response);
      alert("Leave submitted successfully");
    } catch (error) {
      console.error("Failed to submit leave:", error);
    }
  };

  return (
    <div>
      {/* Add Leave */}
      <div className="modal fade" id="add-units">
        <div className="modal-dialog modal-dialog-centered custom-modal-two">
          <div className="modal-content">
            <div className="page-wrapper-new p-0">
              <div className="content">
                <div className="modal-header border-0 custom-modal-header">
                  <div className="page-title">
                    <h4>Apply Leave</h4>
                  </div>
                  <button
                    type="button"
                    className="close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">×</span>
                  </button>
                </div>
                <div className="modal-body custom-modal-body">
                  <form onSubmit={handleSubmit}>
                    <div className="row">
                      <div className="col-lg-12">
                        <div className="input-blocks">
                          <label>Date Range</label>
                          <div className="input-groupicon calender-input">
                            <RangePicker
                              value={selectedDate}
                              onChange={handleDateChange}
                              className="filterdatepicker"
                              dateFormat="dd-MM-yyyy"
                              placeholder={["Start Date", "End Date"]}
                              getPopupContainer={(triggerNode) =>
                                triggerNode.parentNode
                              }
                            />
                          </div>
                        </div>
                      </div>

                      {dateRange.map((item, index) => (
                        <div key={index} className="col-lg-12">
                          <div className="apply-leave">
                            <div className="leave-apply">
                              <div className="leave-date">
                                <span>{item.day}</span>
                                <p>{item.date}</p>
                              </div>
                              <div className="leave-time">
                                <div className="input-blocks mb-0">
                                  <Select
                                    className="select"
                                    options={leaveDayOptions}
                                    placeholder="Select Leave Day"
                                    onChange={(selectedOption) =>
                                      handleLeaveTypeChange(
                                        index,
                                        selectedOption
                                      )
                                    }
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}

                      <div className="col-lg-12">
                        <div className="mb-3">
                          <label className="form-label">
                            Select Leave Type
                          </label>

                          <Select
                            className="select"
                            options={leaveType}
                            placeholder="Select Leave Day"
                            onChange={setSelectedLeaveType}
                          />
                        </div>
                      </div>

                      <div className="col-lg-12">
                        <div className="mb-3 summer-description-box mb-0">
                          <label>Reason</label>

                          <TextEditor
                            onChange={(event) => setReason(event.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="modal-footer-btn">
                      <button
                        type="button"
                        className="btn btn-cancel me-2"
                        data-bs-dismiss="modal"
                      >
                        Cancel
                      </button>

                      <button className="btn btn-submit">Submit</button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* /Add Leave */}
    </div>
  );
};

export default AddLeaveEmployee;
