import React, { useState, useEffect } from 'react';
import { Image, Modal, Spin, Table } from 'antd';
import { PlusCircle } from 'react-feather';
import { Link } from 'react-router-dom';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { deleteCategory, getCategory } from '../../service/ApiServices';
import Swal from 'sweetalert2';
import CreateCategoryModal from './addCategory';
import { API_BASE_URL } from "../../../src/environment";

const Category = () => {
  const [search, setSearch] = useState('');
  const [debouncedSearch, setDebouncedSearch] = useState(search);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [previewImage, setPreviewImage] = useState('');
  const queryClient = useQueryClient();




  const storedUserData = localStorage.getItem("user");
  const userData = JSON.parse(storedUserData);
  const id = userData?.data?.uid; //
  const [userRightssub, setUserRightssub] = useState([]);
  useEffect(() => {
    const fetchUserRightssub = async () => {
      try {
        const response = await fetch(`${API_BASE_URL}/userright/${id}`);
        if (!response.ok) {
          throw new Error("Failed to fetch user rights");
        }
        const data = await response.json();
        console.log("Fetched User Sub Data:", data.data);
        setUserRightssub(data.data || []); // Ensure it's always an array
      } catch (error) {
        console.error("Error fetching data:", error);
        setUserRightssub([]); // Set to empty array in case of error
      }
    };
  
    fetchUserRightssub(); // Fetch the data on component mount
  }, [id]);
  const hasJobRightWithAdd = userRightssub.some(right => right.right === 'category-list' && right.adds === 1);
  const hasJobRightWithedit = userRightssub.some(right => right.right === 'category-list' && right.updates === 1);
  //const hasJobRightWithedown = userRightssub.some(right => right.right === 'category-list' && right.fdownload === 1);  
  const hasJobRightWithedel = userRightssub.some(right => right.right === 'category-list' && right.deletes=== 1); 
    




  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearch(search);
    }, 500);
    return () => {
      clearTimeout(handler);
    };
  }, [search]);

  const fetchCategory = () => getCategory(debouncedSearch);

  const { isLoading, error, data } = useQuery(['categories', debouncedSearch], fetchCategory, {
    staleTime: Infinity,
    cacheTime: Infinity,
    refetchOnWindowFocus: false,
  });

  const mutation = useMutation( deleteCategory);

  const handleDelete = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to delete this category?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
      preConfirm: async () => {
        Swal.showLoading(); 
        try {
          await mutation.mutateAsync(id);
          Swal.fire({
            title: "Success",
            text: "Category deleted successfully!",
            icon: "success",
          });
          queryClient.invalidateQueries('categories');
        } catch (error) {
          let errorMessage = "Failed to process request.";
          if (error.response) {
            const { status, data } = error.response;
            if (status === 400 && data.message) {
              errorMessage = data.message;
            } else if (status === 500) {
              errorMessage = "Server error. Please try again later.";
            }
          }
          Swal.fire({
            title: "Error",
            text: errorMessage,
            icon: "error",
          });
        }
      },
      allowOutsideClick: () => !Swal.isLoading()
    });
  }

  const showWarning = (str) => {
    return Swal.fire({
      title: "Error",
      text: str,
      icon: "error",
    });
  };

  const columns = [
    {
      title: 'Category Pic',
      dataIndex: 'image',
      key: 'image',
      render: (image) => (
        <Image
          src={image}
          alt="Profile Picture"
          width={75}
          height={75}
          style={{
           // borderRadius: '50%',
            objectFit: 'cover',
          }}
          preview={{
            zIndex: 10000,
            visible: previewOpen,
            src: previewImage,
            onVisibleChange: (visible) => setPreviewOpen(visible),
          }}
          onClick={() => {
            setPreviewImage(image);
            setPreviewOpen(true);
          }}
        />
      ),
      width: "230px"
    },
    {
      title: 'Name',
      dataIndex: 'title',
      key: 'title',
      width: "330px"
    },
    {
      title: 'Sub Category',
      dataIndex: ['parertId', 'title'],
      key: 'parertId.title',
      render: (_, record) => {
        return record.parentId?  <span>{record.parentId.title}</span>:<span className="badge bg-danger">N/A</span>;
      }
    },
    {
      title: 'Created At',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (_, record) => {
        return <span>{new Date(record.createdAt).toLocaleDateString()}</span>;
      }
    },
    {
      title: 'Action',
      key: 'action',
      render: (_, record) => (
        <td className="action-table-data">
            {!hasJobRightWithedit && (
               <Link
               className="me-2 p-2"
               onClick={() => {
                 setSelectedCategory(record);
                 setIsModalOpen(true);
               }}>
               <i data-feather="edit" className="feather-edit"></i>
             </Link>
             )}
            {!hasJobRightWithedel && (
                <Link
              className="me-2 p-2"
              onClick={() => {
                handleDelete(record._id);
              }}

            >
              <i data-feather="trash-2" className="feather-trash-2"></i>
            </Link>
             )}



         
          
        </td>
      ),
    },
  ];

  if (error) {
    showWarning('An error occurred while fetching data');
  }

  const handleSearch = (e) => {
    setSearch(e.target.value);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setSelectedCategory(null);
  };

  return (
    <div className="page-wrapper">
      <div className="content">
        <div className="page-header">
          <div className="add-item d-flex">
            <div className="page-title">
              <h4>Categories</h4>
              <h6>Manage all your categories from below</h6>
            </div>
          </div>
          <div className="page-btn">
            {!hasJobRightWithAdd && (
              <a className="btn btn-added" onClick={() => setIsModalOpen(true)}>
                <PlusCircle className="me-2" />
                Add New Category
              </a>
              )}
          </div>
        </div>
        <div className="card table-list-card">
          <div className="card-body">
            <div className="table-top">
              <div className="search-set">
                <div className="search-input">
                  <input
                    type="search"
                    className="form-control"
                    placeholder="Search"
                    value={search}
                    onChange={handleSearch}
                  />
                  <Link className="btn btn-searchset">
                    <i data-feather="search" className="feather-search" />
                  </Link>
                </div>
                {/*<div className="wordset">*/}
                {/*  <ul>*/}
                {/*    <li>*/}
                {/*      <a href="#" data-bs-toggle="tooltip" data-bs-placement="top" title="pdf">*/}
                {/*        <img src="assets/img/icons/pdf.svg" alt="pdf" />*/}
                {/*      </a>*/}
                {/*    </li>*/}
                {/*    <li>*/}
                {/*      <a href="#" data-bs-toggle="tooltip" data-bs-placement="top" title="excel">*/}
                {/*        <img src="assets/img/icons/excel.svg" alt="excel" />*/}
                {/*      </a>*/}
                {/*    </li>*/}
                {/*    <li>*/}
                {/*      <a href="#" data-bs-toggle="tooltip" data-bs-placement="top" title="print">*/}
                {/*        <img src="assets/img/icons/printer.svg" alt="print" />*/}
                {/*      </a>*/}
                {/*    </li>*/}
                {/*  </ul>*/}
                {/*</div>*/}
              </div>
            </div>
            <div className="table-responsive">
              {isLoading ? (
                <div style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: '100px',
                }}>
                  <Spin size='large' />
                </div>
              ) : (
                <Table
                  columns={columns}
                  dataSource={data?.data?.data?.category || []}
                  pagination={{
                    pageSize: 10,
                    showSizeChanger: false,
                  }}
                />
              )}
            </div>
            <Modal
              zIndex={1200}
              open={isModalOpen}
              onCancel={handleCancel}
              footer={null}
              maskClosable={false}
              closable={false}
              width="45vw"
            >
              <CreateCategoryModal
                handleCancel={handleCancel}
                selectedCategory={selectedCategory}
              />
            </Modal>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Category;
